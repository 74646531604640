import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useLocation } from 'react-router-dom';

import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { Calendar } from 'react-date-range';
import axios from 'axios';

import AuthorBlock from "./AuthorBlock";
import Loader from "../../../components/Loader/Loader";
import VideoPublication from "./VideoPublication";
import ImagesPublication from "./ImagesPublication";
import ImageEditor from "../../../components/ImageEditor/ImageEditor";
import AddCategoryPage from "../AddCategoryPage";
import DeletePublicationModel from "../DeletePublicationModel";
import Error from "../../error/Error404";
import UploadMediaPopup from "../UploadMediaPopup"

import Home from '../../../images/icon-home.svg';
import link from "../../../images/link.svg";
import send from "../../../images/send.svg";
import uploadCloud from "../../../images/upload-cloud.svg";
import iconDivider from "../../../images/chevron-right-light.svg";
import closeIcon from "../../../images/x-close.svg";
import fileIcon from "../../../images/file-icon.svg";
import fileDocIcon from "../../../images/file-doc-icon.svg";
import fileDocxIcon from "../../../images/file-docx-icon.svg";
import filePdfIcon from "../../../images/file-pdf-icon.svg";
import filePptIcon from "../../../images/file-ppt-icon.svg";
import filePptmIcon from "../../../images/file-pptm-icon.svg";
import filePptxIcon from "../../../images/file-pptx-icon.svg";
import fileXlsIcon from "../../../images/file-xls-icon.svg";
import fileXlsmIcon from "../../../images/file-xlsm-icon.svg";
import fileXlsxIcon from "../../../images/file-xlsx-icon.svg";
import plus from "../../../images/plus_author.svg";
import calendar from '../../../images/calendar.svg'
import dots from "../../../images/dots.svg";
import linkExternal from "../../../images/link-external.svg";
import coverImg from "../../../images/picture.svg";
import search from "../../../images/search.svg";
import iconDropDown from "../../../images/icondropdown.svg";
import featured from "../../../images/featured.svg";
import { dataURLtoFile } from "../../../utils/functions"
import iconLock from "../../../images/locked.svg"
import { UpgradePopUp } from "../../../components/modal/UpgradePopUp";
import NotificationMessage from "../../../components/NotificationMessage";
import Notification from "../../../components/Notification";
import { EditorComponent } from '../../../components/TiptapEditor/Editor'

function NewPublication({ userData, setActivePage, setHideUserInfo, billingDetails, billingData, showUpgradeModel, setShowUpgradeModel }) {
  setActivePage("Publications");
  const isFreePlan = billingDetails?.subscriptions?.plan == "free_plan" ? true : false;
  setHideUserInfo(true);
  const { id, publicationid } = useParams();
  const [publicationType, setPublicationType] = useState("Regular");
  const [PubID, setPubID] = useState(null);
  const [isSavedLink, setIsisSavedLink] = useState(false);
  const [canAccess, setCanAccess] = useState(true);

  const fileInputRef = useRef(null);
  const pulicationFileInputRef = useRef(null);

  const [featureImage, setFeatureImage] = useState(null);
  const [saveFeatureImage, setSaveFeatureImage] = useState(null);
  const [oldImage, setOldImage] = useState(null);
  const [loadingFeatureImg, setloadingFeatureImg] = useState(false);
  const [replaceImgTimeLoading, setReplaceImgTimeLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isFileDragging, setIsFileDragging] = useState(false);
  const [isSlugChanged, setIslugChanged] = useState(false);
  const [isImageDragging, setIsImageDragging] = useState(false);
  const [loadingFiles, setloadingFiles] = useState(false);
  const [isPublicationFiles, setIsPublicationFiles] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadedFileSize, setUploadedFileSize] = useState(0);
  const [uploadedFileIcon, setUploadedFileIcon] = useState(fileIcon);

  const [featuredImageModal, setFeaturedImageModal] = useState(false);
  const [selectedFeatureImageData, setSelectedFeatureImageData] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [uploadMediaPopupTab, setUploadMediaPopupTab] = useState('upload');
  const [selectedVideoFileData, setSelectedVideoFileData] = useState('');
  const [videoPublicationData, setVideoPublicationData] = useState('')
  const [selectedImageFileData, setSelectedImageFileData] = useState('');
  const [publicationFile, setPublicationFile] = useState(null);
  const [publicationVideo, setPublicationVideo] = useState(null);
  const [featureCropedImageId, setFeatureCropedImageId] = useState('');
  const [featureImageId, setFeatureImageId] = useState('');
  const modalRef = useRef(null);
  const [zoom, setZoom] = useState(1);
  const [oldZoom, setOldZoom] = useState(1);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [replcaeImageZoom, setReplcaeImageZoom] = useState(false);
  const [apiCall, setApiCall] = useState(false)
  const [publicationCheckVideoLink, setPublicationCheckVideoLink] = useState({
    checkBoxStatus: true,
    imageUrl: ""
  })
  // Start Search
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [showCategoryField, setShowCategoryField] = useState(false);
  const [searchCategory, setSearchCategory] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [updatedContent, setUpdatedContent] = useState("");

  const [isOpen, setOpen] = useState(false);

  // Schedule
  let userTimeZone = localStorage.getItem("userTimeZone");
  let userTimeZoneParts = (userTimeZone && typeof userTimeZone === 'string') ? userTimeZone.split(' ') : [];
  let scheduleTimezone = (userTimeZoneParts.length > 1) ? userTimeZoneParts[1] : new Intl.DateTimeFormat().resolvedOptions().timeZone;

  let clientTimeZone = moment.tz.names().find((tz) => tz.includes(scheduleTimezone));
  let clientTimeFormate = moment.tz(clientTimeZone).format();
  let clientDate = DateTime.fromISO(clientTimeFormate, { zone: clientTimeZone });

  let clientDateyear = clientDate.year;
  let clientDatemonth = clientDate.month - 1;
  let clientDatedate = clientDate.day;
  let clientDatehours = clientDate.hour + 1;
  let clientDateminutes = clientDate.minute;
  let clientCurrentDate = new Date(clientDateyear, clientDatemonth, clientDatedate, clientDatehours, clientDateminutes);

  const calendarRef = useRef(null);
  const dateSelectorRef = useRef(null);
  const hourRef = useRef(null);
  const minuteRef = useRef(null);
  const [isSchedule, setIsSchedule] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(clientCurrentDate);
  const [scheduleHour, setScheduleHour] = useState(scheduleDate.getHours().toString().padStart(2, '0'));
  const [scheduleMinute, setScheduleMinute] = useState(scheduleDate.getMinutes().toString().padStart(2, '0'));
  const [saveScheduleDate, setSaveScheduleDate] = useState(false);
  const [saveScheduleHour, setSaveScheduleHour] = useState(false);
  const [saveScheduleMinute, setSaveScheduleMinute] = useState(false);

  const [timeErrorMessage, setTimeErrorMessage] = useState('');

  const [isImageFromLink, setIsImageFromLink] = useState(true);
  const [isHasImage, setIsHasImage] = useState(true);
  const [showUploadMediaPopup, setShowUploadMediaPopup] = useState(false);
  const [selectedFeatureImage, setSelectedFeatureImage] = useState('');
  const [selectMediaLoading, setSelectMediaLoading] = useState(false);
  const [uploadMediaPopupFor, setUploadMediaPopupFor] = useState('');
  const [updateDraftNotify, setUpdateDraftNotify] = useState(false);
  const [scheduleNotify, setScheduleNotify] = useState(false)
  const [categoriesNotify, setCategoriesNotify] = useState(false)

  const profileSlug = localStorage.getItem('userProfileSlug')

  const baseUrl = `https://jourmal-next-front.vercel.app/${profileSlug}/`;
  let notificationTimeoutId = '';

  const iconMapping = {
    pdf: filePdfIcon,
    doc: fileDocIcon,
    docx: fileDocxIcon,
    ppt: filePptIcon,
    pptm: filePptmIcon,
    pptx: filePptxIcon,
    xls: fileXlsIcon,
    xlsm: fileXlsmIcon,
    xlsx: fileXlsxIcon,
  };

  const datetimevalidationCheck = (type, value) => {
    let isValid = true;

    if (!isSchedule) {
      setTimeErrorMessage('');
      return isValid;
    }

    let clientTimeFormateOnChange = moment.tz(clientTimeZone).format();
    let clientDateOnChange = DateTime.fromISO(clientTimeFormateOnChange, { zone: clientTimeZone });

    let clientDateyearOnChange = clientDateOnChange.year;
    let clientDatemonthOnChange = clientDateOnChange.month - 1;
    let clientDatedateOnChange = clientDateOnChange.day;
    let clientDatehoursOnChange = clientDateOnChange.hour;
    let clientDateminutesOnChange = clientDateOnChange.minute;

    let clientScheduleHour = scheduleHour;
    let clientScheduleMinute = scheduleMinute;
    let clientScheduleDate = scheduleDate;

    if (type === 'hours') {
      clientScheduleHour = value;
    }

    if (type === 'minutes') {
      clientScheduleMinute = value;
    }

    if (type === 'Date') {
      clientScheduleDate = value;
    }

    if (parseInt(clientScheduleDate.getFullYear()) <= parseInt(clientDateyearOnChange)) {
      if (parseInt(clientScheduleDate.getMonth()) <= parseInt(clientDatemonthOnChange)) {
        if (parseInt(clientScheduleDate.getDate()) <= parseInt(clientDatedateOnChange)) {
          if (parseInt(clientDatehoursOnChange) > parseInt(clientScheduleHour)) {
            setTimeErrorMessage('This time has already passed, please choose a time in the future.');
            isValid = false;
          } else {
            if (parseInt(clientDatehoursOnChange) == parseInt(clientScheduleHour)) {
              if (parseInt(clientDateminutesOnChange) > parseInt(clientScheduleMinute)) {
                setTimeErrorMessage('This time has already passed, please choose a time in the future.');
                isValid = false;
              } else {
                setTimeErrorMessage('');
                isValid = true;
              }
            } else {
              setTimeErrorMessage('');
              isValid = true;
            }
          }
        } else {
          setTimeErrorMessage('');
          isValid = true;
        }
      } else {
        setTimeErrorMessage('');
        isValid = true;
      }
    } else {
      setTimeErrorMessage('');
      isValid = true;
    }

    return isValid;
  }

  const setBlobData = async () => {
    setSelectMediaLoading(true);
    try {
      const imageData = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
        selectedFeatureImage,
        { responseType: 'blob' }
      );

      const imageUrl = URL.createObjectURL(imageData.data);

      setZoom(1);

      setUploadedImage(imageUrl);
      setOldImage(imageUrl);
      setImageFile(null);

      if (featureImage === null || featureImage === '') {
        setFeatureImage(imageUrl);
      }

      setloadingFeatureImg(false);
      setFeaturedImageModal(true);
      setReplcaeImageZoom(false);

      setPageImage(imageUrl);

      setSelectedFeatureImageData(selectedFeatureImage);

      setSelectMediaLoading(false);
      setShowUploadMediaPopup(false);
      setUploadMediaPopupFor('');
      setImageFile(null);

    } catch (error) {
      setSelectMediaLoading(false);
      console.log({ error: error, message: error.message })
    }
    setSelectMediaLoading(false);
  }

  useEffect(() => {
    if (selectedFeatureImage !== '') {

      if (uploadMediaPopupFor === 'feature-image') {
        setBlobData();
      } else if (uploadMediaPopupFor === 'images-file') {
        setSelectedImageFileData(selectedFeatureImage)
        setSelectMediaLoading(false);
        setShowUploadMediaPopup(false);
        setUploadMediaPopupFor('');
      } else {
        setSelectedFile([selectedFeatureImage._id]);

        if (uploadMediaPopupFor === 'video-file') {
          setSelectedVideoFileData(selectedFeatureImage);
        }

        if (uploadMediaPopupFor === 'docfiles-selection') {
          const extension = selectedFeatureImage.object_id.slice(((selectedFeatureImage.object_id.lastIndexOf('.') - 1) >>> 0) + 2);

          setUploadedFileSize(selectedFeatureImage.size);
          setUploadedFileName(selectedFeatureImage.name);
          setUploadedFileIcon(iconMapping[extension] || fileIcon);
          setFileImage(selectedFeatureImage);
          setPublicationFile(null);
          setIsPublicationFiles(true);
        }
        setSelectMediaLoading(false);
        setShowUploadMediaPopup(false);
        setUploadMediaPopupFor('');
      }
    }

  }, [selectedFeatureImage]);

  const handleFileDragOver = (e) => {
    e.preventDefault();
    if (!isDragging) {
      setIsImageDragging(true);
    }
  }

  const handleFileDragLeave = (e) => {
    e.preventDefault();
    setIsImageDragging(false);
  };

  const handleHourInput = (e) => {
    const newHours = parseInt(e.target.value, 10);

    if (!isNaN(newHours) && newHours >= 0 && newHours <= 23) {
      setScheduleHour(newHours);
    } else if (newHours === 24) {
      setScheduleHour(23);
    }

    if (e.target.value.length == 2) {
      hourRef.current.blur();
      minuteRef.current.focus();
      minuteRef.current.select();
    }
  };

  const handleHourInputBlur = (e) => {
    let newHours = e.target.value;

    if (newHours.length == 1) {
      // Add a leading zero for single-digit numbers (1-9)
      newHours = `0${newHours}`;
    }

    if (!isNaN(newHours) && newHours >= 0 && newHours <= 23) {
      setScheduleHour(newHours);
    } else if (newHours >= 24) {
      setScheduleHour(23);
    }
    datetimevalidationCheck('hours', e.target.value);
  }

  const handleMinuteInput = (e) => {
    const newMinutes = parseInt(e.target.value, 10);

    if (!isNaN(newMinutes) && newMinutes >= 0 && newMinutes <= 59) {
      setScheduleMinute(newMinutes);
    } else if (newMinutes === 60) {
      setScheduleMinute(59);
    }

    if (e.target.value.length == 2) {
      minuteRef.current.blur();
    }
  };

  const handleMinuteInputBlur = (e) => {
    let newMinutes = e.target.value;

    if (newMinutes.length == 1) {
      // Add a leading zero for single-digit numbers (1-9)
      newMinutes = `0${newMinutes}`;
    }

    if (!isNaN(newMinutes) && newMinutes >= 0 && newMinutes <= 59) {
      setScheduleMinute(newMinutes);
    } else if (newMinutes >= 59) {
      setScheduleMinute(59);
    }

    datetimevalidationCheck('minutes', e.target.value);
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        (!modalRef.current.contains(event.target) &&
          featuredImageModal === false) ||
        event.target.className === "overlayModal"
      ) {
        setFeaturedImageModal(false);
        setZoom(oldZoom);
        setOldImage(newUploadedImage);
      }

      if (modelRef.current && !modelRef.current.contains(event.target)) {
        setShowUpgradeModel(false);
      }
    };

    if (featuredImageModal) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [featuredImageModal]);

  const [newUploadedImage, setNewUploadedImage] = useState(null);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  const [isOpen3, setIsOpen3] = useState(true);
  const [isOpen4, setIsOpen4] = useState(true);
  const [isOpen5, setIsOpen5] = useState(true);
  const [isOpen6, setIsOpen6] = useState(true);
  const [isOpen7, setIsOpen7] = useState(true);
  const [isOpen8, setIsOpen8] = useState(true);
  const [isOpen9, setIsOpen9] = useState(true);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
    setTypeOpen(false);
  };

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };

  const toggleDropdown3 = () => {
    setIsOpen3(!isOpen3);
  };

  const toggleDropdown4 = () => {
    setIsOpen4(!isOpen4);
  };

  const toggleDropdown5 = () => {
    setIsOpen5(!isOpen5);
  };

  const toggleDropdown6 = () => {
    setIsOpen6(!isOpen6);
  };

  const toggleDropdown7 = () => {
    setIsOpen7(!isOpen7);
  };

  const toggleDropdown8 = () => {
    setIsOpen8(!isOpen8);
  };

  const toggleDropdown9 = () => {
    setIsOpen9(!isOpen9);
  };

  const debounce = (callback, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [uploadedImage, setUploadedImage] = useState(null);

  const [editorContent, setEditorContent] = useState("");

  const handleEditorChange = (event, editor) => {
    if (editorContent.length > 0) {
      setValidateEditorContent(false);
    }
    const data = editor.getData();
    setEditorContent(data);
  };

  const handleImageUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsDataURL(file);
    });
  };

  const [tempTitle, setTempTitle] = useState('');
  const [tempSubTitle, setTempSubTitle] = useState('')
  const [tempContent, setTempContent] = useState('');
  const [tempPublicationType, setTempPublicationType] = useState('');

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [status, setStatus] = useState("");
  const [dbPageTitle, setdbPageTitle] = useState('');
  const [dbPageSubTitle, setdbPageSubTitle] = useState('');
  const [url, setUrl] = useState("");
  const [shareUrl, setShareUrl] = useState("");
  const [typeOpen, setTypeOpen] = useState("");
  const [pageImage, setPageImage] = useState('');
  const [fileImage, setFileImage] = useState('');
  const [validateTitle, setValidateTitle] = useState(false);
  const [validateEditorContent, setValidateEditorContent] = useState(false);
  const [validateSubTitle, setValidateSubTitle] = useState(false);
  const [validateImage, setValidateImage] = useState(false);
  const [validateFileImage, setValidateFileImage] = useState(false);
  const [validateUploadingFile, setValidateUploadingFile] = useState(false);
  const [validateShareUrl, setValidateShareUrl] = useState(false);
  const [validateCategories, setValidateCategories] = useState(false);
  const [contentlength, setContentLength] = useState(0);
  const [data, setData] = useState([])

  const [openNotify, setOpenNotify] = useState(false);
  const [openNotifyDraft, setOpenNotifyDraft] = useState(false);
  const [showUpgradeBtn, setShowUpgradeBtn] = useState(false);
  const linkTitleRef = useRef(null);
  const linkDescRef = useRef(null);
  const linkImgRef = useRef(null);
  const linkDataRef = useRef(null);
  const modelRef = useRef(null);
  const [postData, setPostData] = useState({})
  const [linkData, setlinkData] = useState(false);
  const [isLinkDataLoad, setLinkDataLoad] = useState(false);
  const [linkTitle, setLinkTitle] = useState('')
  const [linkDesc, setLinkDesc] = useState('')
  const [isValidURL, setIsValidURL] = useState('')

  const popupRef = useRef();
  const popupUpgradeRef = useRef();
  const modelUpgradeRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        typeOpen &&
        popupRef.current &&
        !popupRef.current.contains(e.target)
      ) {
        setTypeOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [typeOpen]);

  const updateAuthor = (originalAuthor, updatedAuthor) => {
    const updatedAuthors = authors.map((author) =>
      author === originalAuthor ? updatedAuthor : author
    );
    setAuthors(updatedAuthors);
  };

  const [published, setPublished] = useState(false);
  const [draft, setDraft] = useState(false);
  const [isDraftOpen, setIsDraftOpen] = useState(false);
  const [isPublishedOpen, setIsPublishedOpen] = useState(false);
  const [authors, setAuthors] = useState([{}]);

  const dotsRef = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target != null && isPublishedOpen && (!e.target.classList.contains('publish-li') && !e.target.classList.contains('newpageMoreButtons'))) {
        setIsPublishedOpen(false);
      }
      if (e.target != null && isDraftOpen && (!e.target.classList.contains('publish-li') && !e.target.classList.contains('newpageMoreButtons'))) {
        setIsDraftOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isPublishedOpen, isDraftOpen]);

  useEffect(() => {
    setPubID(publicationid);
    if (publicationType === "Link" || publicationType === "Video") {
      setIsisSavedLink(true);
    }

  }, [publicationid]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupUpgradeRef.current && !popupUpgradeRef.current.contains(event.target)) {
        setShowUpgradeBtn(false);
      }
      if (modelUpgradeRef.current && !modelUpgradeRef.current.contains(event.target)) {
        setShowUpgradeBtn(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${id}`,
      )
      const user = response?.data?.result?.user;

      setAuthors([{ author: `${user?.firstName} ${user?.lastName}`, website: response?.data?.result?.userMeta?.profileUrl }])

      const categoriesData = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories/all/${id}`,
      );
      if (categoriesData.status == 200) {
        const categoriesArray = categoriesData.data.result.map(item => item.name);
        setCategories(categoriesArray);

      }

    } catch (error) {
      console.log(error, "error");
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const resetFields = ($type) => {

    if ($type == publicationType) {
      return false;
    }

    if (tempPublicationType == '' && PubID != null) {
      setTempTitle(dbPageTitle);
      setTempSubTitle(dbPageSubTitle);
      setTempContent(editorContent);
      setTempPublicationType(publicationType);
    }

    setdbPageTitle('');
    setdbPageSubTitle('');
    setEditorContent('');
    setUpdatedContent('')
    setLinkTitle('');
    setLinkDesc('');
    setFileImage('');
    setIsPublicationFiles(false);

    if (PubID == null) {
      setShareUrl('');
      setUrl('');
    }

    setValidateTitle(false);
    setValidateSubTitle(false)
    setValidateEditorContent(false);
    setValidateCategories(false);
    setValidateImage(false);
    setOpenNotifyDraft(false);
    setOpenNotify(false);
    setIsImageFromLink(true);
    setPublicationCheckVideoLink({
      checkBoxStatus: true,
      imageUrl: ""
    })

    if ($type == tempPublicationType) {
      setdbPageTitle(tempTitle);
      setdbPageSubTitle(tempSubTitle);
      setEditorContent(tempContent);
      setUpdatedContent(tempContent);
      setLinkTitle(tempTitle);
      setLinkDesc(tempContent);
    }
  }

  const uploadMeida = async (type) => {
    let uploadedMediaIds = [];

    if (type === 'File') {
      if (publicationFile !== null) {
        const formData = new FormData();
        formData.append('files', publicationFile); // Append each file individually

        try {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
            formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
          );
          uploadedMediaIds = [res.data.result[0]._id];
        } catch (error) {
          console.log(error);
        }
      } else {
        uploadedMediaIds = selectedFile;
      }
    }

    if (type === 'Video') {
      if (publicationVideo !== null) {
        const formData = new FormData();
        formData.append('files', publicationVideo); // Append each file individually

        try {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
            formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
          );
          uploadedMediaIds = [res.data.result[0]._id];
        } catch (error) {
          console.log(error);
        }
      } else {
        uploadedMediaIds = selectedFile;
      }
    }
    return uploadedMediaIds;
  }

  const handleChangeToDraft = async () => {
    if (published) {
      setIsLoading(true)

      let selectedImage = featureImageId;
      let featureCropedImage = featureCropedImageId;

      const featureImageData = await getFeatureImageData(PubID);
      const selectedPublicationFile = await uploadMeida(publicationType);

      selectedImage = featureImageData?.selectedImage || selectedImage;
      featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

      const newPublicationData = {
        title: dbPageTitle,
        subTitle: dbPageSubTitle,
        publication_type: publicationType,
        url_address: url,
        author_id: authorId,
        description: publicationType === 'Regular' ? updatedContent : editorContent,
        author: authors,
        categories: JSON.stringify(selectedCategories),
        image_caption: imageCaption,
        status: "Draft",
        previous_status: "Draft",
        share_url: shareUrl,
        member_id: JSON.parse(localStorage.getItem("userData"))?._id,
        file: fileImage,
        selectedFiles: selectedPublicationFile,
        selectedImages: selectedImageFileData ? selectedImageFileData.map(item => item._id) : [],
        featured_image: {
          selectedImage: selectedImage,
          cropedImage: featureCropedImage,
          position: position,
          zoom: zoom
        }
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/pages/updatepublication/${PubID}`,
        newPublicationData,
      ).then((res) => {
        setIsLoading(false)
        console.log(res);
      }).catch((error) => {
        setIsLoading(false)
        console.log(error);
      })
      setOpenNotifyDraft(true)

      clearTimeout(notificationTimeoutId)
      notificationTimeoutId = setTimeout(() => {
        setOpenNotifyDraft(false)
      }, 15000);

      setPublished(false);
      setStatus(isSchedule ? 'Schedule' : 'Draft');
      setDraft(true);
      setIsPublishedOpen(false);
      setIsDraftOpen(false);
    }
  };

  const handleChangeToPublish = async () => {

    if (!datetimevalidationCheck(null, null)) {
      setIsLoading(false);
      return false;
    }

    if (billingDetails.permission.canPublishPublication === 'no') {
      setIsDraftOpen(false);
      setIsPublishedOpen(false);
      setShowUpgradeModel(true);
      setIsLoading(false);
      return false;
    }

    setIsLoading(true);
    let schedule_data = {};
    if (isSchedule) {
      schedule_data = {
        scheduleFullDate: scheduleDate,
        scheduleYear: scheduleDate.getFullYear(),
        scheduleMonth: scheduleDate.getMonth(),
        scheduleDate: scheduleDate.getDate(),
        scheduleHour: scheduleHour,
        scheduleMinute: scheduleMinute,
        scheduleTimezoneOffset: scheduleDate.getTimezoneOffset(),
        scheduleTimezone: clientTimeZone,
      };

      setSaveScheduleDate(scheduleDate);
      setSaveScheduleHour(scheduleHour);
      setSaveScheduleMinute(scheduleMinute);
    }

    let selectedImage = featureImageId;
    let featureCropedImage = featureCropedImageId;

    const featureImageData = await getFeatureImageData(PubID);
    const selectedPublicationFile = await uploadMeida(publicationType);

    selectedImage = featureImageData?.selectedImage || selectedImage;
    featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

    const newPublicationData = {
      title: dbPageTitle,
      subTitle: dbPageSubTitle,
      publication_type: publicationType,
      url_address: url,
      description: publicationType === 'Regular' ? updatedContent : editorContent,
      author_id: authorId,
      author: authors,
      categories: selectedCategories,
      image_caption: imageCaption,
      status: isSchedule ? 'Schedule' : 'Published',
      previous_status: isSchedule ? 'Schedule' : 'Published',
      share_url: shareUrl,
      isSchedule: isSchedule,
      schedule_data: schedule_data,
      selectedFiles: selectedPublicationFile,
      selectedImages: selectedImageFileData ? selectedImageFileData.map(item => item._id) : [],
      featured_image: {
        selectedImage: selectedImage,
        cropedImage: featureCropedImage,
        position: position,
        zoom: zoom
      }
    }

    await axios.post(
      `${process.env.REACT_APP_API_URL}/pages/updatepublication/${PubID}`,
      newPublicationData,
    ).then((res) => {
      setIsLoading(false)
      console.log(res);
    }).catch((error) => {
      setIsLoading(false)
      console.log(error);
    })
    setOpenNotify(true)

    clearTimeout(notificationTimeoutId)
    notificationTimeoutId = setTimeout(() => {
      setOpenNotify(false)
    }, 15000);

    setPublished(true);
    billingData();
    setDraft(false);
    setIsPublishedOpen(false);
    setIsDraftOpen(false);

    setStatus(isSchedule ? 'Schedule' : 'Published');
  };

  const handlePaste = (e) => {
    e.preventDefault();

    // Get the plain text content from clipboard
    const clipboardData = e.clipboardData.getData('text/plain');

    // Paste the plain text into the contenteditable div
    document.execCommand('insertText', false, clipboardData);
  }

  const authorId = id;
  const [imageCaption, setimageCaption] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDraftLoading, setIsDraftLoading] = useState(false);

  const handleDraft = async () => {
    if (apiCall === false) {

      let selectedImage = featureImageId;
      let featureCropedImage = featureCropedImageId;

      const featureImageData = await getFeatureImageData(id)
      const selectedPublicationFile = await uploadMeida(publicationType);

      selectedImage = featureImageData?.selectedImage || selectedImage;
      featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

      setTempTitle('');
      setTempSubTitle('');
      setTempContent('');
      setTempPublicationType('');

      setIsLoading(true)
      setApiCall(true)
      const newPublicationData = {
        title: dbPageTitle,
        subTitle: dbPageSubTitle,
        publication_type: publicationType,
        url_address: url,
        author_id: authorId,
        author: authors,
        categories: selectedCategories,
        image_caption: imageCaption,
        status: "Draft",
        previous_status: "Draft",
        description: publicationType === 'Regular' ? updatedContent : editorContent,
        share_url: shareUrl,
        member_id: JSON.parse(localStorage.getItem("userData"))?._id,
        selectedFiles: selectedPublicationFile,
        selectedImages: selectedImageFileData ? selectedImageFileData.map(item => item._id) : [],
        featured_image: {
          selectedImage: selectedImage,
          cropedImage: featureCropedImage,
          position: position,
          zoom: zoom
        },
        linkFile_publication_media: publicationType === "Link" || publicationType === "Video" ? publicationCheckVideoLink : undefined
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/pages/updatepublication/${PubID}`,
        newPublicationData,
      )
        .then((res) => {
          setIsLoading(false)
          // setOpenNotifyDraft(true)
          setUpdateDraftNotify(true)

          clearTimeout(notificationTimeoutId)
          notificationTimeoutId = setTimeout(() => {
            setOpenNotifyDraft(false)
          }, 15000);

          if (isLoading === false) {
            setApiCall(false)
          }
        })
        .catch((error) => {
          setIsLoading(false)
          if (isLoading === false) {
            setApiCall(false)
          }
          console.log(error);
        })
    }
  }

  const handleChangeURL = (publicationID) => {
    const newUrl = `/users/${id}/publications/publication/${publicationID}`;  // Replace with the URL you want

    // Change the URL without reloading the page
    window.history.pushState({}, '', newUrl);
  };

  const addData = async (isDraft, status) => {

    setTempTitle('');
    setTempSubTitle('');
    setTempContent('');
    setTempPublicationType('');

    if (publicationid) {
      try {
        let schedule_data = {};
        if (isSchedule) {
          schedule_data = {
            scheduleFullDate: scheduleDate,
            scheduleYear: scheduleDate.getFullYear(),
            scheduleMonth: scheduleDate.getMonth(),
            scheduleDate: scheduleDate.getDate(),
            scheduleHour: scheduleHour,
            scheduleMinute: scheduleMinute,
            scheduleTimezoneOffset: scheduleDate.getTimezoneOffset(),
            scheduleTimezone: clientTimeZone,
          };

          setSaveScheduleDate(scheduleDate);
          setSaveScheduleHour(scheduleHour);
          setSaveScheduleMinute(scheduleMinute);
        }

        let selectedImage = featureImageId;
        let featureCropedImage = featureCropedImageId;

        const featureImageData = await getFeatureImageData(id)
        const selectedPublicationFile = await uploadMeida(publicationType);

        selectedImage = featureImageData?.selectedImage || selectedImage;
        featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

        const newPublicationData = {
          title: dbPageTitle,
          subTitle: dbPageSubTitle,
          publication_type: publicationType,
          url_address: url,
          author_id: authorId,
          author: authors,
          categories: selectedCategories,
          image_caption: imageCaption,
          status: isSchedule && status == 'Published' ? 'Schedule' : status,
          previous_status: isSchedule && status == 'Published' ? 'Schedule' : status,
          description: publicationType === 'Regular' ? updatedContent : editorContent,
          share_url: shareUrl,
          isSchedule: isSchedule,
          schedule_data: schedule_data,
          member_id: JSON.parse(localStorage.getItem("userData"))?._id,
          selectedFiles: selectedPublicationFile,
          selectedImages: selectedImageFileData ? selectedImageFileData.map(item => item._id) : [],
          featured_image: {
            selectedImage: selectedImage,
            cropedImage: featureCropedImage,
            position: position,
            zoom: zoom
          }
        }
        await axios.post(
          `${process.env.REACT_APP_API_URL}/pages/updatepublication/${publicationid}`,
          newPublicationData,
        )
        setIsLoading(false);
        setIsDraftLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsDraftLoading(false);
        console.log(error, "Data not add");
      }

    } else {

      try {

        let schedule_data = {};
        if (isSchedule) {
          schedule_data = {
            scheduleFullDate: scheduleDate,
            scheduleYear: scheduleDate.getFullYear(),
            scheduleMonth: scheduleDate.getMonth(),
            scheduleDate: scheduleDate.getDate(),
            scheduleHour: scheduleHour,
            scheduleMinute: scheduleMinute,
            scheduleTimezoneOffset: scheduleDate.getTimezoneOffset(),
            scheduleTimezone: clientTimeZone,
          };

          setSaveScheduleDate(scheduleDate);
          setSaveScheduleHour(scheduleHour);
          setSaveScheduleMinute(scheduleMinute);
        }

        const newPublicationData = {
          title: dbPageTitle,
          subTitle: dbPageSubTitle,
          publication_type: publicationType,
          url_address: url,
          author_id: authorId,
          author: authors,
          categories: selectedCategories,
          image_caption: imageCaption,
          status: isSchedule && status == 'Published' ? 'Schedule' : status,
          previous_status: isSchedule && status == 'Published' ? 'Schedule' : status,
          share_url: shareUrl,
          description: publicationType === 'Regular' ? updatedContent : editorContent,
          isSchedule: isSchedule,
          schedule_data: schedule_data,
          member_id: JSON.parse(localStorage.getItem("userData"))?._id
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/pages/addpublication`,
          newPublicationData,
        )

        if (response.status === 200) {
          handleChangeURL(response.data.result._id)
          setPubID(response?.data?.result?._id)
          setData(response.data.result);

          let selectedImage = featureImageId;
          let featureCropedImage = featureCropedImageId;

          const featureImageData = await getFeatureImageData(PubID)


          selectedImage = featureImageData?.selectedImage || selectedImage;
          featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

          const updatedPublicationData = {
            ...newPublicationData,
            featured_image: {
              selectedImage: selectedImage,
              cropedImage: featureCropedImage,
              position: position,
              zoom: zoom
            }
          };

          await axios.post(
            `${process.env.REACT_APP_API_URL}/pages/updatepublication/${response?.data?.result?._id}`,
            updatedPublicationData,
          );
        }

        setIsLoading(false);
        setIsDraftLoading(false);
        if (status === "Draft") {
          setOpenNotifyDraft(true)

          clearTimeout(notificationTimeoutId)
          notificationTimeoutId = setTimeout(() => {
            setOpenNotifyDraft(false)
          }, 15000);

        }
        if (status === "Published") {
          setOpenNotify(true)

          clearTimeout(notificationTimeoutId)
          notificationTimeoutId = setTimeout(() => {
            setOpenNotify(false)
          }, 15000);
        }
        if(status === 'Schedule'){
          setScheduleNotify(true)
        }
      } catch (error) {
        setIsLoading(false);
        setIsDraftLoading(false);
        console.log(error, "Data not add");
      }
    }
  }



  const [isPopupOpenDeleteAccount, setIsPopupOpenDeleteAccount] = useState(false);

  const handelSingleDelete = () => {
    setIsDraftOpen(false)
    setIsPublishedOpen(false);
    setIsPopupOpenDeleteAccount(true);
    document.body.classList.add('hidden-message');

  }

  const closeAccountDeletedPopup = () => {
    setIsPopupOpenDeleteAccount(false);
    document.body.classList.remove('hidden-message');
  }

  const closeCategoryField = (action) => {

    setShowCategoryField(false);
    document.body.classList.remove('hidden-message');

    if (action == 'create') {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target != null && isPopupOpenDeleteAccount && (e.target.classList.contains('popupModalOverlay'))) {
        setIsPopupOpenDeleteAccount(false);
      }

      if (e.target != null && showCategoryField && (e.target.classList.contains('popupModalOverlay'))) {
        setShowCategoryField(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isPopupOpenDeleteAccount, showCategoryField]);

  const validateFields = () => {
    if (title.length > 0) {
      setValidateTitle(false);
    } else {
      setValidateTitle(true);
    }
    if (dbPageTitle.length > 0) {
      setValidateTitle(false);
    } else {
      setValidateTitle(true);
    }

    if (subTitle.length > 0) {
      setValidateSubTitle(false);
    } else {
      setValidateSubTitle(true);
    }
    if (publicationType === "Regular" && dbPageSubTitle.length > 0) {
      setValidateSubTitle(false);
    } else {
      setValidateSubTitle(true);
    }
    if(publicationType === 'Regular') {
      if (updatedContent.length > 0) {
        setValidateEditorContent(false);
      } else {
        setValidateEditorContent(true);
      }
    }else {
      if (editorContent.length > 0) {
        setValidateEditorContent(false);
      } else {
        setValidateEditorContent(true);
      }
    }

    if (fileImage === '') {
      setValidateFileImage(true);
    } else {
      setValidateFileImage(false);
    }

    if (pageImage === '') {
      setValidateImage(true);
    } else {
      setValidateImage(false);
    }
    if (selectedCategories.length > 0) {
      setValidateCategories(false);
    } else {
      setValidateCategories(true);
    }

    if (shareUrl === '') {
      setValidateShareUrl(true);
    } else {
      setValidateShareUrl(false);
    }
  }

  const draftValidateFields = () => {
    if (title.length > 0) {
      setValidateTitle(false);
    } else {
      setValidateTitle(true);
    }
    if (dbPageTitle.length > 0) {
      setValidateTitle(false);
    } else {
      setValidateTitle(true);
    }
    if (publicationType === "Link") {
      if (shareUrl.length > 0) {
        setValidateShareUrl(false);
      } else {
        setValidateShareUrl(true);
      }
    }
  }

  useEffect(() => {
    // Use the effect to clear the error message after 2 seconds
    const timer = setTimeout(() => {
      setErrorMessage('');
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [errorMessage]);


  const handleCheckboxChange = (value) => {
    if (selectedCategories.includes(value)) {
      setSelectedCategories(selectedCategories.filter((v) => v !== value));
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
  };

  const filteredCategories = categories.filter((value) =>
    value.toLowerCase().includes(searchCategory.toLowerCase())
  );

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      setSearchCategory(inputValue);
      setInputValue('');
    }
  };

  const handleNewCategoryClick = () => {
    // setInputValue(''); // Clear input when creating a new category
    setShowCategoryField(true); // Hide closeLink    
    // setErrorMessage('');
    document.body.classList.add('hidden-message');
  };

  // Function to scroll the ul to the bottom
  const scrollToBottom = () => {
    var categoriesList = document.querySelector('.categories-list');
    var liElements = categoriesList.querySelectorAll('li');

    let totalHeight = ((parseInt(liElements.length) + 1) * 22) + 5;

    categoriesList.scrollTop = totalHeight;
  };

  // End Search

  // Start Schedule Checkbox 
  const handleScheduleCheckbox = () => {
    setIsSchedule(!isSchedule);
    setShowCalendar(false);
  };

  const handleCalendar = () => {
    if (!showCalendar) {
      setShowCalendar(true);
    }
  };

  const handlePublish = async () => {

    if ((status == 'Published' || status == 'Schedule') && !datetimevalidationCheck(null, null)) {
      return false;
    }

    if (apiCall === false) {

      if (!isImageFromLink && pageImage == '') {
        return setValidateImage(true);
      }

      let schedule_data = {};
      if (isSchedule) {
        let userTimeZone = localStorage.getItem("userTimeZone");
        let userTimeZoneParts = userTimeZone.split(" ");
        let scheduleTimezone = (userTimeZone) ? userTimeZoneParts[1] : new Intl.DateTimeFormat().resolvedOptions().timeZone;
        schedule_data = {
          scheduleFullDate: scheduleDate,
          scheduleYear: scheduleDate.getFullYear(),
          scheduleMonth: scheduleDate.getMonth(),
          scheduleDate: scheduleDate.getDate(),
          scheduleHour: scheduleHour,
          scheduleMinute: scheduleMinute,
          scheduleTimezoneOffset: scheduleDate.getTimezoneOffset(),
          scheduleTimezone: scheduleTimezone,
        };

        setSaveScheduleDate(scheduleDate);
        setSaveScheduleHour(scheduleHour);
        setSaveScheduleMinute(scheduleMinute);
      }

      setTempTitle('');
      setTempSubTitle('');
      setTempContent('');
      setTempPublicationType('');

      setIsLoading(true)
      setApiCall(true)

      let selectedImage = featureImageId;
      let featureCropedImage = featureCropedImageId;

      const featureImageData = await getFeatureImageData(PubID);
      const selectedPublicationFile = await uploadMeida(publicationType);

      selectedImage = featureImageData?.selectedImage || selectedImage;
      featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

      const newPublicationData = {
        title: dbPageTitle,
        subTitle: dbPageSubTitle,
        publication_type: publicationType,
        url_address: url,
        author_id: authorId,
        author: authors,
        categories: selectedCategories,
        image_caption: imageCaption,
        status: isSchedule ? 'Schedule' : 'Published',
        previous_status: isSchedule ? 'Schedule' : 'Published',
        description: publicationType === 'Regular' ? updatedContent : editorContent,
        share_url: shareUrl,
        isSchedule: isSchedule,
        schedule_data: schedule_data,
        member_id: JSON.parse(localStorage.getItem("userData"))?._id,
        selectedFiles: selectedPublicationFile,
        selectedImages: selectedImageFileData ? selectedImageFileData.map(item => item._id) : [],
        featured_image: {
          selectedImage: selectedImage,
          cropedImage: featureCropedImage,
          position: position,
          zoom: zoom
        },
        linkFile_publication_media: publicationType === "Link" || publicationType === "Video" ? publicationCheckVideoLink : undefined
      }
      await axios.post(
        `${process.env.REACT_APP_API_URL}/pages/updatepublication/${PubID}`,
        newPublicationData,
      )
        .then((res) => {
          setIsLoading(false)
          setOpenNotify(true)

          setStatus(isSchedule ? 'Schedule' : 'Published');

          clearTimeout(notificationTimeoutId)
          notificationTimeoutId = setTimeout(() => {
            setOpenNotify(false)
          }, 15000);

          if (isLoading === false) {
            setApiCall(false)
          }
        })
        .catch((error) => {
          setIsLoading(false)
          if (isLoading === false) {
            setApiCall(false)
          }
        })
    }
  }

  const getFeatureImageData = async (publicationID) => {
    let selectedImage = featureImageId;
    let featureCropedImage = featureCropedImageId;

    if (imageFile !== null) {

      const imageFormData = new FormData();
      imageFormData.append('files', imageFile); // Append each file individually
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
          imageFormData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        );
        selectedImage = res.data.result[0]._id;

        const featureImageFile = await dataURLtoFile(featureImage, "feature-image.jpg");
        const formData = new FormData();
        formData.append('files', featureImageFile);
        formData.append('id', publicationID);
        const uploadedMedia = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
          formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        );

        featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;

      } catch (error) {
        console.log(error);
      }
    } else if ((selectedFeatureImageData?._id) || oldImage !== saveFeatureImage) {
      const featureImageFile = await dataURLtoFile(featureImage, "feature-image.jpg");

      const formData = new FormData();
      formData.append('files', featureImageFile);
      formData.append('id', publicationID);

      const uploadedMedia = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
        formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      );

      selectedImage = selectedFeatureImageData?._id || selectedImage;
      featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;
    }

    const result = { selectedImage, featureCropedImage }

    return result;
  }

  const findSinglePublication = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/pages/${publicationid}?userId=${id}`);

      if (res) {
        setdbPageTitle(res?.data?.result?.title);
        setStatus(res?.data?.result?.status);
        setdbPageSubTitle(res?.data?.result?.subTitle);
        setPublicationType(res?.data?.result?.publication_type);
        setUrl(res?.data?.result?.url_address);
        setEditorContent(res?.data?.result?.description);
        setUpdatedContent(res?.data?.result?.description);

        setData(res.data?.result);
        if (res?.data?.result?.publication_type == 'Link' && res?.data?.result?.share_url) {
          setShareUrl(res?.data?.result?.share_url);
          const urlData = { url: res?.data?.result?.share_url };
          const shareUrlresponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/publicationurl/link`,
            urlData
          );
          setPostData(shareUrlresponse?.data?.previewData);
          setlinkData(true);
          setLinkDataLoad(true);
          setPublicationCheckVideoLink({
            checkBoxStatus: res?.data?.result?.linkFile_publication_media?.checkBoxStatus || true,
            imageUrl: shareUrlresponse?.data?.linkFile_publication_media?.imageUrl || ""
          })
          setIsImageFromLink(res?.data?.result?.linkFile_publication_media?.checkBoxStatus)
        }

        if (res?.data?.result?.publication_type == 'Video' && res?.data?.result?.share_url) {
          setShareUrl(res?.data?.result?.share_url);
        }

        if (res?.data?.result?.status === "Published" || res?.data?.result?.status === "Schedule") {
          setPublished(true);
        }
        if (res?.data?.result?.status === "Draft") {
          setDraft(true);
        }

        setIsSchedule(res?.data?.result?.isSchedule);
        setTimeErrorMessage('');

        let schedule_data = res?.data?.result?.schedule_data;

        setScheduleDate(schedule_data && schedule_data['scheduleFullDate'] ? new Date(schedule_data['scheduleFullDate']) : clientCurrentDate);
        setScheduleHour(schedule_data && schedule_data['scheduleHour'] ? schedule_data['scheduleHour'] : clientCurrentDate.getHours().toString().padStart(2, '0'));
        setScheduleMinute(schedule_data && schedule_data['scheduleMinute'] ? schedule_data['scheduleMinute'] : clientCurrentDate.getMinutes().toString().padStart(2, '0'));

        setSaveScheduleDate(schedule_data && schedule_data['scheduleFullDate'] ? new Date(schedule_data['scheduleFullDate']) : false);
        setSaveScheduleHour(schedule_data && schedule_data['scheduleHour'] ? schedule_data['scheduleHour'] : false);
        setSaveScheduleMinute(schedule_data && schedule_data['scheduleMinute'] ? schedule_data['scheduleMinute'] : false);

        let selectedCat = [];
        res?.data?.result?.categories?.map((item) => {
          selectedCat.push(item);
        });
        setSelectedCategories(selectedCat);
        setCanAccess(true);

        const publicationData = res.data?.result || '';

        if (res?.data?.result?.publication_type == 'File') {
          const selectedFiles = res?.data?.result?.selectedFiles || [];
          if (selectedFiles.length > 0) {
            const filesData = res?.data?.result?.filesData || [];
            const selectedFile = filesData[selectedFiles[0]];
            setSelectedFile([selectedFile._id]);
            const extension = selectedFile.object_id.slice(((selectedFile.object_id.lastIndexOf('.') - 1) >>> 0) + 2);

            setUploadedFileSize(selectedFile.size);
            setUploadedFileName(selectedFile.name);
            setUploadedFileIcon(iconMapping[extension] || fileIcon);
            setFileImage(selectedFile);
            setPublicationFile(null);
            setIsPublicationFiles(true);
          }
        }

        if (res?.data?.result?.publication_type == 'Images') {
          const selectedImages = res?.data?.result?.selectedImages || [];
          if (selectedImages.length > 0) {
            const imagesData = res?.data?.result?.filesData || [];
            setSelectedImageFileData(Object.values(imagesData));
          }
        }

        if (res?.data?.result?.publication_type == 'Video') {
          const selectedFiles = res?.data?.result?.selectedFiles || [];
          if (selectedFiles.length > 0) {
            const filesData = res?.data?.result?.filesData || [];
            setSelectedVideoFileData(filesData[selectedFiles[0]]);
            setShareUrl(filesData.url)
          }
        }

        if (publicationData?.featured_image?.selectedImage) {
          const imageData = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
            publicationData.featureImageData.selectedMedia,
            { responseType: 'blob' }
          );

          const imageUrl = URL.createObjectURL(imageData.data);

          setUploadedImage(imageUrl);
          setImageFile(null);
          setNewUploadedImage(imageUrl);
          setOldImage(imageUrl);
          setFeatureImageId(publicationData.featured_image.selectedImage);
        }
        if (publicationData?.featured_image?.cropedImage) {
          const imageData = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
            publicationData.featureImageData.cropedMedia,
            { responseType: 'blob' }
          );

          const imageUrl = URL.createObjectURL(imageData.data);

          setFeatureImage(imageUrl);
          setSaveFeatureImage(imageUrl);
          setFeatureCropedImageId(publicationData.featured_image.cropedImage);

          setPageImage(imageUrl);
        }

        if (publicationData?.featured_image?.zoom) {
          setZoom(publicationData.featured_image.zoom)
          setOldZoom(publicationData.featured_image.zoom)
        }

        if (publicationData?.featured_image?.position) {
          setPosition(publicationData.featured_image.position)
        }
      } else {
        setCanAccess(false);
      }

    } catch (err) {
      console.log(err);
      setCanAccess(false);
    }
  };

  useEffect(() => {
    if (publicationid) {
      if (publicationType === "Link" || publicationType === "Video") {
        setIsisSavedLink(true);
      }
      findSinglePublication()
    } else {
      setIsisSavedLink(false);
      setdbPageTitle('')
      setdbPageSubTitle('')
      setPublicationType('Regular')
      setEditorContent('')
      setUpdatedContent('');
      setUrl('')
      setIsLoading(false)
      setDraft(false)
      setPublished(false)
      setIsDraftLoading(false)
      setSaveFeatureImage(null)

      setFileImage('');
      setIsPublicationFiles(false);

      setSelectedCategories([])

      setIsSchedule(false);
      setShowCalendar(false);
      setScheduleDate(clientCurrentDate);
      setScheduleHour(clientCurrentDate.getHours().toString().padStart(2, '0'));
      setScheduleMinute(clientCurrentDate.getMinutes().toString().padStart(2, '0'));
      setTimeErrorMessage('');

    }
  }, [publicationid])

  const handleCancelUpload = () => {
    // Clear the timeout if the close button is clicked
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setloadingFiles(false);
  };

  const [openPublishPopup, setOpenPublishPopup] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleClick = () => {
    setFeaturedImageModal(!featuredImageModal);
    var divElement = document.getElementById("newpageImageUpload");
    divElement.classList.add("imageUploaded");
  };

  const handleDrop = (e) => {
    var divElement = document.getElementById("newpageImageUpload");

    var extraHeight = (featureImage) ? 2 : 0;

    divElement.style.height = (divElement.clientHeight + extraHeight) + "px";
    divElement.style.minHeight = (divElement.clientHeight + extraHeight) + "px";

    divElement.classList.remove("imageUploaded");
    setloadingFeatureImg(true);

    var TempReplcaeImageZoom = replcaeImageZoom
    var TempReplaceImgTimeLoading = replaceImgTimeLoading

    if (featureImage) {
      setReplcaeImageZoom(true);
      setReplaceImgTimeLoading(false);

      TempReplcaeImageZoom = true;
      TempReplaceImgTimeLoading = false;
    }

    if (TempReplcaeImageZoom === true) {
      setOldZoom(1);
      setZoom(1);
    } else {
      setZoom(oldZoom);
    }

    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        if (file) {
          setUploadedImage(file);
          TempReplcaeImageZoom !== true && setFeatureImage(reader.result);
          setOldImage(reader.result);
        }
      };
    };
    if (file) {
      setTimeout(() => {
        reader.readAsDataURL(file);
        setUploadedImage(file);
        setloadingFeatureImg(false);
        var divElement = document.getElementById("newpageImageUpload");
        divElement.classList.add("imageUploaded");
        divElement.classList.add("imageUploaded-wrap");
        setOldImage(file);
        setFeaturedImageModal(true);
        setReplcaeImageZoom(false);
        setReplaceImgTimeLoading(false)

        // divElement.style.height = 'auto';
        // divElement.style.minHeight = 'unset';
      }, 4000);
    } else {
      setloadingFeatureImg(false);
      // divElement.style.height = 'auto';
      // divElement.style.minHeight = 'unset';
    }
    setIsDragging(false);
  };

  const handleFileUpload = (e) => {
    var divElement = document.getElementById("newpageImageUpload");

    var extraHeight = (featureImage) ? 2 : 0;

    divElement.style.height = (divElement.clientHeight + extraHeight) + "px";
    divElement.style.minHeight = (divElement.clientHeight + extraHeight) + "px";

    divElement.classList.remove("imageUploaded");
    setloadingFeatureImg(true);
    if (replcaeImageZoom === true) {
      setOldZoom(1);
      setZoom(1);
    } else {
      setZoom(oldZoom);
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;
      image.onload = () => {
        replcaeImageZoom !== true && setFeatureImage(reader.result);
        setOldImage(reader.result);
      };
    };
    if (file) {
      reader.readAsDataURL(file);
      setUploadedImage(null);
      setPageImage(file);
      setTimeout(() => {
        reader.readAsDataURL(file);
        setloadingFeatureImg(false);
        setUploadedImage(file);
        var divElement = document.getElementById("newpageImageUpload");
        divElement.classList.add("imageUploaded");
        divElement.classList.add("imageUploaded-wrap");
        setFeaturedImageModal(true);
        setReplcaeImageZoom(false);
        // divElement.style.height = 'auto';
        // divElement.style.minHeight = 'unset';
      }, 4000);
    } else {
      setloadingFeatureImg(false);
      // divElement.style.height = 'auto';
      // divElement.style.minHeight = 'unset';
    }
  };

  const [timeoutId, setTimeoutId] = useState(null);


  const handlePulicationFileUpload = (e) => {
    setValidateFileImage(false);
    setValidateUploadingFile(false);
    setloadingFiles(true);
    const file = e.target.files[0];

    if (timeoutId) {
      // Clear the previous timeout if it exists
      clearTimeout(timeoutId);
    }

    if (file) {

      if (validateFileType(file) && validateFileSize(file)) {

        const extension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);

        setUploadedFileName(file.name);
        setUploadedFileSize(file.size);

        setUploadedFileIcon(iconMapping[extension] || fileIcon);

        const newTimeoutId = setTimeout(() => {
          setFileImage(file);
          setIsPublicationFiles(true);
          setloadingFiles(false);
        }, 4000);

        // Save the timeout ID for later reference
        setTimeoutId(newTimeoutId);
      } else {
        setValidateUploadingFile(true);
        setloadingFiles(false);
      }
    } else {
      setloadingFiles(false);
    }
  }

  const handleFileDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    setloadingFiles(true);
    setValidateFileImage(false);
    setValidateUploadingFile(false);

    if (timeoutId) {
      // Clear the previous timeout if it exists
      clearTimeout(timeoutId);
    }

    if (file) {
      // const file = files[0];

      if (validateFileType(file) && validateFileSize(file)) {
        const extension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);

        setUploadedFileName(file.name);
        setUploadedFileSize(file.size);

        setUploadedFileIcon(iconMapping[extension] || fileIcon);

        // Handle file upload logic here
        const newTimeoutId = setTimeout(() => {
          setFileImage(file);
          setIsPublicationFiles(true);
          setloadingFiles(false);
        }, 4000);

        // Save the timeout ID for later reference
        setTimeoutId(newTimeoutId);
      } else {
        setloadingFiles(false);
        setValidateUploadingFile(true);
      }
    } else {
      setloadingFiles(false);
    }

    setIsFileDragging(false);
  };

  const validateFileType = (file) => {
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    return allowedTypes.includes(file.type);
  };

  const validateFileSize = (file) => {
    const maxSize = 128 * 1024 * 1024; // 128MB in bytes
    return file.size <= maxSize;
  };

  const handleImageLoad = () => {
    const img = new Image();
    img.src = featureImage;
    img.onload = () => { };
  };

  const handleReplaceImg = () => {
    // document.getElementById("feature-img").click();
    setShowUploadMediaPopup(true);
    setUploadMediaPopupFor('feature-image');
    setFeaturedImageModal(false);
    setReplcaeImageZoom(true);
    setReplaceImgTimeLoading(false);
  };

  useEffect(() => {
    setZoom(zoom);
  }, [zoom]);

  const handleZoom = (zoom) => {
    setZoom(zoom);
  };

  const handlePosition = (newPosition) => {
    setPosition(newPosition);
  };

  const handleSavePicture = async () => {
    let click = document.getElementById("clickFunction");
    click.click();
    setFeatureImage(featureImage);
    setNewUploadedImage(oldImage);
    setSaveFeatureImage(featureImage);
    setFeaturedImageModal(false);
    setOldZoom(zoom);
  };

  const handleMouseDown = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.add("slider-cursor");
  };

  const handleMouseUp = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.remove("slider-cursor");
  };

  const handleTouchStart = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.add("slider-cursor");
  };

  const handleTouchEnd = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.remove("slider-cursor");
  };

  const handleCancel = () => {
    if (!saveFeatureImage) {
      var divElement = document.getElementById("newpageImageUpload");
      featureImage && divElement.classList.remove("imageUploaded");
    } else {
      var divElements = document.getElementById("newpageImageUpload");
      featureImage && divElements.classList.add("imageUploaded");
    }
    setOldImage(newUploadedImage);
    setFeaturedImageModal(false);
    setZoom(oldZoom);

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      setUploadedImage(null);
    }
  };

  function humanReadableFileSize(bytes) {
    if (bytes === 0) return '0 B';

    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  function convertToSlug(str) {
    // Convert the string to lowercase
    let slug = str.toLowerCase();

    // Remove starting and ending spaces  
    slug = slug.trim();

    // Replace spaces with a single hyphen "-"
    slug = slug.replace(/\s+/g, '-').replace(/\.+/g, '-');

    return slug;
  }

  const changeSlug = (value) => {
    if (!isSlugChanged) {
      let urlValue = convertToSlug(value);
      setUrl(urlValue);
    }
  }

  const changeTitle = (e) => {
    //Stop enter button

    if (e.key === 'Enter') {
      e.preventDefault();
      return true;
    }

  }

  const changesTitles = (e) => {
    const text = e.target.innerText.replace(/<[^>]+>/g, '');

    if (text.length <= 120 || e.key === 'Backspace') {
      // setPageTitle(text)
      changeSlug(text);
    } else {
      e.preventDefault();
      return true;
    }
  }

  const handleBlur = (e) => {
    const text = e.target.innerText.replace(/<[^>]+>/g, '');
    setdbPageTitle(text)
  }


  const changeSubTitle = (e) => {
    //Stop enter button
    if (e.key === 'Enter') {
      e.preventDefault();
      return true;
    }
    if (e.target.innerText.length >= 240 && e.key !== 'Backspace') {
      e.preventDefault();
      return true;
    }
  }


  const changesSubTitles = (e) => {

    // Stop after 120 characters and enable backspace
    const text = e.target.innerText.replace(/<[^>]+>/g, '');

    if (text.length <= 240 || e.key === 'Backspace') {
      // setSubTitle(text)
      //changeSlug(text);
    } else {
      e.preventDefault();
      return true;
    }
  }

  const handleSubTitleBlur = (e) => {
    let text = e.target.innerText.replace(/<[^>]+>/g, '');
    if (text.length <= 240) {
      // do nothing
    } else {
      // Truncate the content to the maximum length
      text = text.slice(0, 241);
    }
    setdbPageSubTitle(text)
  }


  useEffect(() => {
    const handleResize = () => {
      if (linkData && linkTitleRef.current) {
        linkTitleRef.current.style.height = '40px';
        linkTitleRef.current.style.height = linkTitleRef.current.scrollHeight + 2 + 'px';
      }

      if (linkData && linkDescRef.current) {
        linkDescRef.current.style.height = '40px';
        var extraSpace = 2;

        if (linkDescRef.current.scrollHeight > 80) {
          extraSpace = 10;
        }

        linkDescRef.current.style.height = linkDescRef.current.scrollHeight + extraSpace + 'px';
      }

      if (isSavedLink === true && publicationType === "Link") {
        setIsisSavedLink(false)
      }
    };

    // if( publicationType == 'Link' ) {
    handleResize();

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);
    // }

    // Cleanup: Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [linkTitle, linkDesc, linkData]);


  const handleLinkTitleInput = (e) => {
    if (isSavedLink === false) {
      e.target.style.height = '40px';
      e.target.style.height = e.target.scrollHeight + 2 + 'px';

      setLinkTitle(e.target.value);
      setdbPageTitle(e.target.value);
      if (e.target.value.trim().length > 0) {
        setValidateTitle(false);
      }
    }
  }

  const handleLinkTitleBlur = (e) => {
    const newtitleVal = e.target.value.trim();
    if (newtitleVal.length == 0) {
      setLinkTitle(postData.title);
      setdbPageTitle(postData.title);
    }
  }

  const handleLinkDescInput = (e) => {
    if (isSavedLink === false) {
      e.target.style.height = '40px';

      var extraSpace = 2;

      if (e.target.scrollHeight > 80) {
        extraSpace = 20;
      }

      e.target.style.height = e.target.scrollHeight + extraSpace + 'px';

      setLinkDesc(e.target.value);
      setEditorContent(e.target.value);
      if (e.target.value.trim().length > 0) {
        setValidateEditorContent(false);
      }
    }
  }

  const handleLinkDescBlur = (e) => {
    const newtitleVal = e.target.value.trim();
    if (newtitleVal.length == 0) {
      setLinkDesc(postData.description);
      setEditorContent(postData.description);
    }
  }

  const handleShareInputChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setShareUrl(trimmedValue);

    setIsValidURL('');

    if (trimmedValue.length > 0) {
      setValidateShareUrl(false);
      setIsValidURL(false);
    }

    setlinkData(false);
    setLinkDataLoad(false);
  };

  const handleDateSelectorClick = (e) => {
    e.stopPropagation(); // Prevent click events from bubbling up
    handleCalendar();
  };

  // Start Check Featured image
  const handleFeatureCheckbox = (e) => {
    setIsImageFromLink(e.target.checked);
    if (e.target.checked === true) {
      if (publicationType === "Link") {
        setPublicationCheckVideoLink({
          checkBoxStatus: true,
          imageUrl: postData?.imageUrl
        })
      } else {
        if (publicationType === "Video") {
          setPublicationCheckVideoLink({
            checkBoxStatus: true,
            imageUrl: postData?.imageUrl
          })
        }
      }
    } else {
      if (e.target.checked === false) {
        setPublicationCheckVideoLink({
          checkBoxStatus: false,
          imageUrl: ""
        })
      }
    }
  };
  // End Check Featured image
  const handleChangePlanPopup = () => {
    setShowUpgradeBtn(true);
  };
  return (
    <>
      {
        canAccess ?
          <section className="dashboard new-publication new-publication-loader">
            <div className="commonPage">
              <div className="dashboardTop">
                <div className={`newpageContent newPublication publication-${publicationType}`}>
                  <div className="newpageLeft publicationLeft">
                    <div className="breadcrumbs">
                      <Link to={'/'} ><img src={Home} className="home-icon" alt="home-icon" /></Link>
                      <img className="divider" src={iconDivider} alt="dropdown" />
                      <Link to={`/users`} ><span className="">Users</span></Link>
                      <img className="divider" src={iconDivider} alt="dropdown" />
                      <Link to={`/users/${id}`} ><span className="">{userData.firstName} {userData.lastName}</span></Link>
                      <img className="divider" src={iconDivider} alt="dropdown" />
                      <Link to={`/users/${id}/publications`} ><span className="">Publications</span></Link>
                      {publicationid == undefined && (
                        <>
                          <img className="divider" src={iconDivider} alt="dropdown" />
                          <Link to={`/users/${id}/publications/publication`} ><span className="">New Publication</span></Link>
                        </>
                      )}
                    </div>

                    {publicationType === "Video" && (
                      <VideoPublication
                        PubID={PubID}
                        dbPageTitle={dbPageTitle}
                        setdbPageTitle={setdbPageTitle}
                        shareUrl={shareUrl}
                        editorContent={editorContent}
                        setEditorContent={setEditorContent}
                        validateTitle={validateTitle}
                        validateEditorContent={validateEditorContent}
                        setValidateTitle={setValidateTitle}
                        setValidateEditorContent={setValidateEditorContent}
                        setShareUrl={setShareUrl}
                        setIsHasImage={setIsHasImage}
                        setIsImageFromLink={setIsImageFromLink}
                        setShowUploadMediaPopup={setShowUploadMediaPopup}
                        setUploadMediaPopupFor={setUploadMediaPopupFor}
                        setUploadMediaPopupTab={setUploadMediaPopupTab}
                        selectedVideoFileData={selectedVideoFileData}
                        videoPublicationData={videoPublicationData}
                        setVideoPublicationData={setVideoPublicationData}
                        setPublicationVideo={setPublicationVideo}
                        setPublicationCheckVideoLink={setPublicationCheckVideoLink}
                        isImageFromLink={isImageFromLink}
                      />
                    )}

                    {publicationType === "Images" && (
                      <ImagesPublication
                        PubID={PubID}
                        dbPageTitle={dbPageTitle}
                        setdbPageTitle={setdbPageTitle}
                        editorContent={editorContent}
                        setEditorContent={setEditorContent}
                        validateTitle={validateTitle}
                        validateEditorContent={validateEditorContent}
                        setValidateTitle={setValidateTitle}
                        setValidateEditorContent={setValidateEditorContent}
                        setShowUploadMediaPopup={setShowUploadMediaPopup}
                        setUploadMediaPopupFor={setUploadMediaPopupFor}
                        setUploadMediaPopupTab={setUploadMediaPopupTab}
                        selectedImageFileData={selectedImageFileData}
                        setSelectedImageFileData={setSelectedImageFileData}
                      />
                    )
                    }

                    {publicationType === "Regular" && (
                      <>
                        <div className={`newpageLeftRow textBlock editor-block ${validateEditorContent ? 'validateError inputError' : ''}`}>
                          <div className="newpageLeftRow">
                            <input type="hidden" placeholder="Title" value={dbPageTitle} onChange={(e) => { changeSlug(e.target.value); }} />
                            <div
                              id="post-title"
                              value={dbPageTitle}
                              placeholder="Write your title..."
                              contentEditable="true"
                              onKeyDown={(e) => {
                                changeTitle(e)
                                if (e.target.innerText.length > 0) {
                                  setValidateTitle(false);
                                }
                              }}
                              onBlur={(e) => handleBlur(e)}
                              onKeyUp={(e) => changesTitles(e)}
                              onPaste={(e) => handlePaste(e)}>{dbPageTitle}</div>
                          </div>
                          {validateTitle && (
                            <p className="validateError">The field is not filled</p>
                          )}

                          <div className="newpageLeftRow">
                            <input type="hidden" placeholder="Enter a publication sub-title..." value={dbPageSubTitle} />
                            <div
                              id="post-sub-title"
                              placeholder="Write your sub-title..."
                              contentEditable="true"
                              onInput={(e) => {
                                if (e.target.innerText.length >= 240) {
                                  setContentLength(240)
                                } else {
                                  setContentLength(e.target.innerText.length)
                                }
                              }}
                              onKeyDown={(e) => {
                                changeSubTitle(e)
                                if (e.target.innerText.length > 0) {
                                  setValidateSubTitle(false);
                                }
                              }}
                              onBlur={(e) => handleSubTitleBlur(e)}
                              onKeyUp={(e) => changesSubTitles(e)}
                              onPaste={(e) => handlePaste(e)}>{dbPageSubTitle}</div>
                            <p className="charactersLeft">{contentlength}/240</p>
                          </div>
                          {validateSubTitle && (
                            <p className="validateError">The field is not filled</p>
                          )}
                        </div>
                      </>
                    )}

                    {publicationType === "Regular" ? (
                      <>
                        <div className={`newpageLeftRow textBlock ${validateEditorContent ? 'validateError inputError' : ''}`}>
                          <EditorComponent setUpdatedContent={setUpdatedContent} editorContent={editorContent} type="publication" id={publicationid}/>
                        </div>
                        {validateEditorContent === true ? (
                          <p className="validateError">The field is not filled</p>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {publicationType === "Link" ? (
                      <>
                        <div className="newpageLeftRow textInfoBlock">
                          <h2>Link to URL</h2>
                          <p>Integrate all your digital footprints into one cohesive online presence, so you no longer have to juggle multiple URLs or worry about your audience not finding your diverse content spread across the web.</p>
                          <p>Simply provide the URL you wish to include and your visitors will be forwarded directly to your chosen destination. It’s not only enhances the visibility of your online materials, but also ensures that everything associated with your name is found in one place, instantly, with just one click.</p>
                        </div>
                        <div className="newpageLeftRow textBlock">
                          <label className="fieldsLabel">Share a URL</label>
                          <div className="adressInputBlock urlInput">
                            <img
                              className="adressLink adressLinkLeftInput"
                              src={link}
                              alt="Link"
                            />
                            <input
                              className="urlInputLeft inputFields"
                              type="text"
                              placeholder="Paste publication’s URL"
                              value={shareUrl}
                              onChange={handleShareInputChange}
                              autocomplete="off"
                            />
                          </div>
                          {validateShareUrl === true ? (
                            <p className="validateError">The field is not filled</p>
                          ) : (
                            ""
                          )}
                          {isValidURL === false && shareUrl.length > 0 ? (
                            <p className="validateError">The URL is not valid</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <>
                      {(publicationType === "Link" && shareUrl.length > 0 && isLinkDataLoad === false) ? (
                        <>
                          <div className="custom-skeleton">
                            <div className="custom-skeleton-picture">
                              <div className="custom-skeleton-item"></div>
                            </div>
                            <div className="custom-skeleton-content">
                              <div className="custom-skeleton-item custom-skeleton-title"></div>
                              <div className="custom-skeleton-item"></div>
                              <div className="custom-skeleton-item"></div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {(linkData === true && shareUrl.length > 0 && publicationType === "Link") ? (
                            <>
                              <div className="newpageLeftRow textBlock linkBox">
                                {
                                  postData?.image_url &&
                                  <img
                                    className="previewImage"
                                    src={postData?.image_url}
                                    alt="Preview"
                                    ref={linkImgRef}
                                    onLoad={() => {
                                      if (linkData && isLinkDataLoad && publicationType === "Link" && linkDataRef.current) {
                                        const linkDataHeight = linkDataRef.current.clientHeight;
                                        linkImgRef.current.style.width = linkDataHeight + 'px';
                                      }
                                    }}
                                  />
                                }
                                <div className="linkData" ref={linkDataRef}>
                                  <p className="linkDataTitle">{postData?.title}</p>
                                  <span className="linkDataDiscripation">{postData?.description}</span>
                                </div>
                              </div>
                              <div className="newpageLeftRow newpageCard linkDataBox linkBox">
                                <div className="linkData">
                                  <label className="inputFields" >Page title</label>
                                  <textarea id="linkDataTitle" className="inputFields" placeholder="Type title..." ref={linkTitleRef} onChange={(e) => handleLinkTitleInput(e)} onBlur={(e) => handleLinkTitleBlur(e)} value={dbPageTitle}></textarea>
                                  {validateTitle && (
                                    <p className="validateError">The field is not filled</p>
                                  )}
                                  <label className="inputFields">Description</label>
                                  <textarea id="linkDataDiscripation" placeholder="Write a short description..." className="inputFields" ref={linkDescRef} onChange={(e) => handleLinkDescInput(e)} onBlur={(e) => handleLinkDescBlur(e)} value={editorContent}></textarea>
                                  {validateEditorContent && (
                                    <p className="validateError">The field is not filled</p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </>

                    {publicationType === "File" ? (
                      <>
                        <div className="newpageLeftRow textInfoBlock">
                          <h2>
                            Upload a File
                          </h2>
                          <p>
                            Upload and display PDF and Office documents for your visitors to view, from detailed reports and data-rich spreadsheets to compelling presentations. You can share important content without the need for external downloads or additional software.
                          </p>
                          <p>
                            It's designed to provide a smooth, in-browser viewing experience that keeps your audience engaged and ensures your files are presented exactly as you intended.
                          </p>
                          <div className="newpagenotes">
                            <p>
                              <strong>Note:</strong> PDF files will be conveniently displayed in a new tab for direct and immediate viewing. However, Office documents will require downloading to ensure full compatibility and proper rendering.
                            </p>
                          </div>
                          <div className="newpageLeftRow newpageCard linkDataBox linkBox newpagefile">
                            <div className="linkData">
                              <p className="label" >Page title</p>
                              <textarea id="linkDataTitle" className="linkDataTitle" placeholder="Type title..." ref={linkTitleRef} onChange={(e) => handleLinkTitleInput(e)} onBlur={(e) => handleLinkTitleBlur(e)} value={dbPageTitle}></textarea>
                              {validateTitle && (
                                <p className="validateError">The field is not filled</p>
                              )}
                              <p className="label">Description</p>
                              <textarea id="linkDataDiscripation" placeholder="Write a short description about the file..." className="linkDataDiscripation" ref={linkDescRef} onChange={(e) => handleLinkDescInput(e)} onBlur={(e) => handleLinkDescBlur(e)} value={editorContent}></textarea>
                              {validateEditorContent && (
                                <p className="validateError">The field is not filled</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="newpageLeftRow textBlock">
                          <div className={isFileDragging ? `draging fileUploadBox` : `fileUploadBox`}
                            onDragOver={handleFileDragOver}
                            onDragLeave={handleFileDragLeave}
                            onDrop={handleFileDrop}
                          >
                            <input
                              type="file"
                              ref={pulicationFileInputRef}
                              id="pulication-file"
                              onChange={handlePulicationFileUpload}
                              accept=".pdf, .doc, .docx, .ppt, .pptx, .ppsx, .xls, .xlsx"
                              hidden
                            />

                            {loadingFiles === true ? (
                              <div className="uploadFileBlock preloadFile">
                                <div className="uploadFileBlockDetails">
                                  <div className="fileUploadedCover_wrapp">
                                    <img
                                      className="fileUploadedCover_Img"
                                      src={uploadedFileIcon}
                                      alt="FeaturedImage"
                                    />
                                  </div>

                                  <p className="uploaded-file-name">
                                    {uploadedFileName}
                                  </p>
                                  <p className="uploaded-file-size">
                                    {humanReadableFileSize(uploadedFileSize)}
                                  </p>
                                </div>
                                <div className="featureImg-loader loadingCloseWrap">
                                  <Loader />
                                  <div className="loadingclose" onClick={handleCancelUpload}>
                                    <img src={closeIcon} alt="icon-close" />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="uploadFileBlock"
                                onClick={() => {
                                  // document.getElementById("pulication-file").click()
                                  setShowUploadMediaPopup(true);
                                  setUploadMediaPopupFor('docfiles-selection');
                                  setUploadMediaPopupTab('upload');
                                }}
                              >
                                {isPublicationFiles === true ? (
                                  <div className="featureImgMiddleContent fileUploadedCover">
                                    <div className="fileUploadedCover_wrapp">
                                      <img
                                        className="fileUploadedCover_Img"
                                        src={uploadedFileIcon}
                                        alt="FeaturedImage"
                                      />
                                    </div>
                                    <p className="uploaded-file-name">
                                      {uploadedFileName}
                                    </p>
                                    <p className="uploaded-file-size">
                                      {humanReadableFileSize(uploadedFileSize)}
                                    </p>
                                    <p className="newPageImageUploadText">
                                      <span>Open file gallery</span> or <span onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('docfiles-selection'); setUploadMediaPopupTab('media'); }}>upload</span> to replace
                                      <br />
                                      pdf, doc, docx, ppt, pptx, pptm, xls, xlsx, xlsm (max. 128MB)
                                    </p>
                                  </div>
                                ) : (
                                  <div className="featureImgMiddleContent">
                                    <img
                                      className="fileUploadCover_Img"
                                      src={uploadCloud}
                                      alt="FeaturedImage"
                                    />
                                    <p className="newPageImageUploadText">
                                      <span>Open file gallery</span> or <span onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('docfiles-selection'); setUploadMediaPopupTab('media'); }}>upload</span>{" "}
                                      <br />
                                      pdf, doc, docx, ppt, pptx, pptm, xls, xlsx, xlsm (max. 128MB)
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        {validateUploadingFile === true ? (
                          <p className="validateError">Invalid file type or size</p>
                        ) : (
                          ""
                        )}
                        {validateFileImage === true ? (
                          <p className="validateError">The field is not filled</p>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="newpageRight">
                    <div className="buttonsDesktop">
                      {published === false && draft === false ? (
                        <div className="newpageRightButtons">
                          <button
                            onClick={(e) => {
                              validateFields();
                              if (pageImage === '') {
                                setValidateImage(true);
                              } else {
                                setValidateImage(false);
                              }
                              if (billingDetails.permission.canPublishPublication === 'no') {
                                setIsDraftOpen(false);
                                setIsPublishedOpen(false);
                                setShowUpgradeModel(true);
                                return false;
                              }
                              if (publicationType === "Regular") {
                                if (dbPageTitle.length > 0 && dbPageSubTitle.length > 0 && updatedContent.length > 0 && pageImage !== '' && selectedCategories.length > 0 && timeErrorMessage == '') {

                                  if (!datetimevalidationCheck(null, null)) {
                                    return false;
                                  }

                                  setIsLoading(true);
                                  addData(true, "Published");
                                  setStatus(isSchedule ? 'Schedule' : 'Published');
                                  setPublished(true);
                                  billingData();
                                  setOpenPublishPopup(true);
                                }
                              }
                              if (publicationType === "Link") {
                                if (dbPageTitle.length > 0 && editorContent.length > 0 && shareUrl.length > 0 && selectedCategories.length > 0 && timeErrorMessage == '') {

                                  if (!isImageFromLink && pageImage !== '') {
                                    return false;
                                  }

                                  if (!datetimevalidationCheck(null, null)) {
                                    return false;
                                  }
                                  setIsLoading(true);
                                  addData(true, "Published");
                                  setStatus(isSchedule ? 'Schedule' : 'Published');
                                  setPublished(true);
                                  billingData();
                                  setOpenPublishPopup(true);
                                }
                              }
                              if (publicationType === "File") {
                                if (dbPageTitle.length > 0 && editorContent.length > 0 && pageImage !== '' && selectedCategories.length > 0 && fileImage !== '' && timeErrorMessage == '') {

                                  if (!datetimevalidationCheck(null, null)) {
                                    return false;
                                  }

                                  setIsLoading(true);
                                  addData(true, "Published");
                                  setStatus(isSchedule ? 'Schedule' : 'Published');
                                  setPublished(true);
                                  billingData();
                                  setOpenPublishPopup(true);
                                }
                              }
                              if (publicationType === "Video") {
                                if (dbPageTitle.length > 0 && editorContent.length > 0 && selectedCategories.length > 0 && timeErrorMessage == '') {

                                  if (!datetimevalidationCheck(null, null)) {
                                    return false;
                                  }

                                  setIsLoading(true);
                                  addData(true, "Published");
                                  setStatus(isSchedule ? 'Schedule' : 'Published');
                                  setPublished(true);
                                  billingData();
                                  setOpenPublishPopup(true);;
                                }
                              }
                              if (publicationType === "Images") {
                                if (dbPageTitle.length > 0 && editorContent.length > 0 && pageImage !== '' && selectedCategories.length > 0 && timeErrorMessage == '') {

                                  if (!datetimevalidationCheck(null, null)) {
                                    return false;
                                  }

                                  setIsLoading(true);
                                  addData(true, "Published");
                                  setStatus(isSchedule ? 'Schedule' : 'Published');
                                  setPublished(true);
                                  billingData();
                                  setOpenPublishPopup(true);
                                }
                              }
                            }}
                          >
                            {isLoading ?
                              <div className="publishbutton-loader">
                                <div className="ring-loader"></div>
                              </div>
                              :
                              <>
                                <img src={send} alt="Send" />
                                <p>{isSchedule ? 'Schedule' : 'Publish'}</p>
                              </>}
                          </button>
                          <button onClick={(e) => {
                            draftValidateFields();
                            if (dbPageTitle.length > 0) {
                              setIsDraftLoading(true);
                              addData(true, "Draft");
                              setStatus('Draft');
                              setDraft(true);
                            }
                          }}>
                            {isDraftLoading ?
                              <div className="draftButton-loader">
                                <div className="ring-loader cancel"></div>
                              </div>
                              :
                              <>
                                Save as draft
                              </>}
                          </button>
                        </div>
                      ) : (
                        ""
                      )}

                      {published === true ? (
                        <div>
                          <div className="newpageRightButtons" ref={dotsRef}>

                            <button onClick={(e) => { handlePublish(e) }}>
                              {isLoading ?
                                <div className="updatebutton-loader ">
                                  <div className="ring-loader"></div>
                                </div>
                                :
                                <>
                                  <p>Update</p>
                                </>}
                            </button>
                            <button
                              className="newpageMoreButtons"
                              onClick={(e) => setIsPublishedOpen(!isPublishedOpen)}
                            >
                              <img src={dots} alt="Dots" />
                            </button>

                            {isPublishedOpen ? (
                              <div className="publishedButtonsList">
                                <ul>
                                  <li
                                    className="publish-li"
                                    onClick={handleChangeToDraft}
                                  >
                                    Change to draft
                                  </li>
                                  <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <p className="publishStatus">
                            {
                              status == 'Schedule' ?
                                <>Status: <span>{status}d for {saveScheduleDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} at {saveScheduleHour}:{saveScheduleMinute}</span></>
                                :
                                <>Status: <span>{status}</span></>
                            }
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      {draft === true ? (
                        <div>
                          <div className="newpageRightButtons" ref={dotsRef}>
                            <button onClick={(e) => { handleDraft(e) }}>
                              {isLoading ?
                                <div className="updatebutton-loader ">
                                  <div className="ring-loader"></div>
                                </div>
                                :
                                <>
                                  <p>Update</p>
                                </>}
                            </button>
                            <button
                              className="newpageMoreButtons"
                              onClick={(e) => setIsDraftOpen(!isDraftOpen)}
                            >
                              <img src={dots} alt="Dots" />
                            </button>

                            {isDraftOpen ? (
                              <div className="publishedButtonsList">
                                <ul>
                                  <li
                                    className="publish-li"
                                    onClick={(e) => {
                                      validateFields();
                                      if (publicationType === "Regular") {
                                        if (dbPageTitle.length > 0 && dbPageSubTitle.length > 0 && updatedContent.length > 0 && pageImage !== '' && selectedCategories.length > 0) {
                                          handleChangeToPublish(e, () => setIsLoading(true))
                                        }
                                      }
                                      if (publicationType === "Link") {
                                        if (dbPageTitle.length > 0 && editorContent.length > 0 && shareUrl.length > 0 && selectedCategories.length > 0) {
                                          handleChangeToPublish(e, () => setIsLoading(true))
                                        }
                                      }
                                      if (publicationType === "File") {
                                        if (dbPageTitle.length > 0 && editorContent.length > 0 && pageImage !== '' && selectedCategories.length > 0 && fileImage !== '') {
                                          handleChangeToPublish(e, () => setIsLoading(true))
                                        }
                                      }
                                      if (publicationType === "Images") {
                                        if (dbPageTitle.length > 0 && editorContent.length > 0 && pageImage !== '' && selectedCategories.length > 0) {
                                          handleChangeToPublish(e, () => setIsLoading(true))
                                        }
                                      }
                                    }}
                                  >
                                    {isSchedule ? "Schedule" : "Publish"}
                                  </li>
                                  <li className="publish-li" onClick={handelSingleDelete} >Delete</li>
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <p className="publishStatus">
                            Status: <span className="draftStatus">{status}</span>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="newPageRightRow publication-type" ref={popupRef}>
                      <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown1}>
                        <span>
                          Publication type
                        </span>
                        <div className="dropdownIcon">
                          <img src={iconDropDown} alt="dropdown" />
                        </div>
                      </p>
                      <div className={`contentDrop ${isOpen1 ? 'expanded' : 'collapse'}`}>
                        <div>
                          <p className="newPageRightFullUrl">
                            <span>Display at the end of the publication...</span>
                          </p>

                          <div className="select pubTypeSelect">
                            <div
                              className="selectMain"
                              onClick={(e) => setTypeOpen(!typeOpen)}
                            >

                              <p>{publicationType}</p>
                            </div>
                            {typeOpen === true ? (
                              <div className="selectListOption">
                                <div
                                  className="selectOption"
                                  onClick={(e) => {
                                    setPublicationType("Regular");
                                    setTypeOpen(false);
                                    resetFields("Regular");
                                  }}
                                >
                                  <p>Regular</p>
                                </div>
                                <div
                                  className="selectOption"
                                  onClick={(e) => {
                                    setPublicationType("Link");
                                    setTypeOpen(false);
                                    resetFields("Link");
                                  }}
                                >
                                  <p>Link</p>
                                </div>
                                <div style={{ position: "relative" }}>
                                  {isFreePlan &&
                                    <div className='overlayLock-publications-type' onClick={handleChangePlanPopup} > </div>
                                  }
                                  <div
                                    className={`selectOption ${isFreePlan ? 'lock' : ''}`}
                                    onClick={(e) => {
                                      if (!isFreePlan) {
                                        setPublicationType("File");
                                      } else {
                                        handleChangePlanPopup()
                                      }
                                      setTypeOpen(false);
                                      resetFields("File");
                                    }}
                                  >

                                    <p>File</p>
                                    {isFreePlan &&
                                      <div className='overlayLock-publication' >
                                        <div className="planLayout">
                                          <img src={iconLock} alt="lock-icon" />
                                        </div>
                                      </div>
                                    }

                                  </div>
                                  <div
                                    className={`selectOption ${isFreePlan ? 'lock' : ''}`}
                                    onClick={(e) => {
                                      if (!isFreePlan) {
                                        setPublicationType("Video");
                                      } else {
                                        handleChangePlanPopup()
                                      }
                                      setTypeOpen(false);
                                      resetFields("File");
                                    }}
                                  >
                                    <p>Video</p>
                                    {isFreePlan &&
                                      <div className='overlayLock-publication'>
                                        <div className="planLayout">
                                          <img src={iconLock} alt="lock-icon" />
                                        </div>
                                      </div>
                                    }
                                  </div>
                                  <div
                                    className={`selectOption ${isFreePlan ? 'lock' : ''}`}
                                    onClick={(e) => {
                                      if (!isFreePlan) {
                                        setPublicationType("Images");
                                      } else {
                                        handleChangePlanPopup()
                                      }
                                      setTypeOpen(false);
                                      resetFields("Images");
                                    }}
                                  >
                                    <p>Images</p>
                                    {isFreePlan &&
                                      <div className='overlayLock-publication'>
                                        <div className="planLayout">
                                          <img src={iconLock} alt="lock-icon" />
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {publicationType === "Regular" ? (
                      <div className="newPageRightRow">
                        <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown2}>
                          <span>URL address</span>
                          <div className="dropdownIcon">
                            <img src={iconDropDown} alt="dropdown" />
                          </div>
                        </p>

                        <div className={`contentDrop ${isOpen2 ? 'expanded' : 'collapse'}`}>
                          <div>
                            <p className="newPageRightFullUrl">
                              <span>Display at the end of the publication...</span>
                            </p>
                            <div className="adressInputBlock urlInput">
                              <img className="adressLink" src={link} alt="Link" />
                              <input
                                id="page-slug"
                                type="text"
                                placeholder="URL"
                                value={url || dbPageTitle}
                                className="inputFields"
                                autocomplete="off"
                                onChange={(e) => {
                                  setUrl(e.target.value);
                                  setIslugChanged(true)
                                  if (e.target.value === '') {
                                    setIslugChanged(false)
                                  }
                                }}
                                onBlur={(e) => {
                                  let urlValue = convertToSlug(e.target.value);
                                  setUrl(urlValue);
                                  if (e.target.value === '') {
                                    changeSlug(title);
                                    setIslugChanged(false)
                                  }
                                }}
                              />
                            </div>
                            <p className="newPageRightFullUrl slug-box">
                              Full URL:
                              {published === true && status == 'Published' ? (
                                <Link
                                  className="pageurl"
                                  target={"_blank"}
                                  to={`${baseUrl}${url}` || dbPageTitle}
                                >
                                  <span>{baseUrl}{url || dbPageTitle}</span>
                                  <img className="link-external" src={linkExternal} alt="external link" />
                                </Link>
                              ) : (
                                <span className="pageurl">
                                  http://www.jourmal.com/bar-tov/{url || dbPageTitle}
                                </span>
                              )}
                            </p>
                          </div>
                        </div>

                      </div>
                    ) : (
                      ""
                    )}

                    {publicationType === "Link" ? (
                      <div className={`newPageRightRow ${isOpen6 ? 'openPT' : ''}`}>
                        <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown6}>
                          <span>URL address</span>
                          <div className="dropdownIcon">
                            <img src={iconDropDown} alt="dropdown" />
                          </div>
                        </p>
                        <div className={`contentDrop ${isOpen6 ? 'expanded' : 'collapse'}`}>
                          <div>
                            <p className="newPageRightFullUrl">
                              <span>Display at the end of the publication...</span>
                            </p>
                            <div className="adressInputBlock urlInput">
                              <img className="adressLink" src={link} alt="Link" />
                              <input
                                className="inputReadOnly type-link-url"
                                type="text"
                                placeholder="URL"
                                value={"URL is taken from shared link"}
                                autocomplete="off"
                                //onChange={(e) => setUrl(e.target.value)}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {publicationType === "File" ? (
                      <div className="newPageRightRow">
                        <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown7}>
                          <span>URL address</span>
                          <div className="dropdownIcon">
                            <img src={iconDropDown} alt="dropdown" />
                          </div>
                        </p>

                        <div className={`contentDrop ${isOpen7 ? 'expanded' : 'collapse'}`}>
                          <div>
                            <p className="newPageRightFullUrl">
                              <span>Display at the end of the publication...</span>
                            </p>
                            <div className="adressInputBlock urlInput">
                              <img className="adressLink" src={link} alt="Link" />
                              <input
                                className="inputReadOnly"
                                type="text"
                                placeholder="URL"
                                value={"URL is taken from file name"}
                                onChange={(e) => setUrl(e.target.value)}
                                readOnly
                                autocomplete="off"
                              />
                            </div>
                            <p className="newPageRightFullUrl">
                              Full URL:{" "}
                              <span className="pageurl">
                                http://www.jourmal.com/bar-tov/
                              </span>
                            </p>
                          </div>
                        </div>

                      </div>
                    ) : (
                      ""
                    )}

                    <div className="newPageRightRow dateFieldDrop">
                      <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown9}>
                        <span>Date & Time</span>
                        <div className="dropdownIcon">
                          <img src={iconDropDown} alt="dropdown" />
                        </div>
                      </p>
                      <div className={`contentDrop ${isOpen9 ? 'expanded' : 'collapse'}`}>
                        <div className="checkboxView list-search schedule-checkbox">
                          <label>
                            <input type="checkbox" checked={isSchedule} onChange={handleScheduleCheckbox} />
                            <p>Schedule Publication</p>
                          </label>

                          {
                            isSchedule &&
                            <>
                              <div className="dateTimeCheckbox">
                                <p className="schedule-date-selector" ref={dateSelectorRef} onClick={handleDateSelectorClick} > {scheduleDate != null && scheduleDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} <img src={calendar} alt="Calendar" /></p>
                                <div className={`schedule-time-selector ${timeErrorMessage != '' ? 'inputError' : ''}`}>
                                  <input ref={hourRef} className={`schedule-hour ${scheduleHour == 1 && scheduleHour.length < 1 ? 'shortest-width' : ''} ${(scheduleHour == 0 || (scheduleHour > 1 && scheduleHour < 9)) && scheduleHour.length < 1 ? 'very-short-width' : ''} ${scheduleHour == 11 ? 'short-width' : ''} ${scheduleHour < 20 && scheduleHour > 11 ? 'normal-width' : ''}`} autocomplete="off" id="schedule-hour" maxlength="2" pattern="^(0[0-9]|1[0-9]|2[0-3])$" min={0} max={23} inputmode="numerical" placeholder="HH" type="text" value={scheduleHour} onChange={handleHourInput} onBlur={(e) => handleHourInputBlur(e)} data-pattern-validate />
                                  <span className="verticle-dots">:</span>
                                  <input ref={minuteRef} className={`schedule-minute ${scheduleMinute && scheduleMinute.length < 1 == 1 ? 'shortest-width' : ''} ${(scheduleMinute == 0 || (scheduleMinute > 1 && scheduleMinute < 9)) && scheduleMinute.length < 1 ? 'very-short-width' : ''} ${scheduleMinute == 11 ? 'short-width' : ''} ${scheduleMinute < 20 && scheduleMinute > 11 ? 'normal-width' : ''}`} autocomplete="off" id="schedule-minute" maxlength="2" pattern="[0-9]*" min={0} max={59} inputmode="numerical" placeholder="mm" type="text" value={scheduleMinute} onChange={handleMinuteInput} onBlur={(e) => handleMinuteInputBlur(e)} data-pattern-validate />
                                </div>
                              </div>
                              {
                                showCalendar && (
                                  <div ref={calendarRef} className="calendar-container" onClick={(e) => e.stopPropagation()} >
                                    <Calendar onChange={item => { setScheduleDate(item); setShowCalendar(false); datetimevalidationCheck('Date', item) }} date={scheduleDate} minDate={new Date(clientTimeFormate)} />
                                  </div>
                                )
                              }
                              {timeErrorMessage && <div className="error-message"><p className="error-text">{timeErrorMessage}</p></div>}
                            </>
                          }
                        </div>
                      </div>
                    </div>

                    {publicationType === "Regular" ? (
                      <div className="newPageRightRow">
                        <p className="newPageRightTitle authorTitle iconDropWrap" onClick={toggleDropdown3}>
                          <span>Authors</span>
                          <div className="dropdownIcon">
                            <img src={iconDropDown} alt="dropdown" />
                          </div>
                        </p>

                        <div className={`contentDrop ${isOpen3 ? 'expanded' : 'collapse'}`}>
                          <div>
                            <p className="newPageRightFullUrl">
                              <span>Display at the end of the publication...</span>
                            </p>
                            <div className="authorsBlocks">
                              {authors.map((author, index) => (
                                <AuthorBlock key={index} data={author} updateAuthor={updateAuthor} />
                              ))}
                            </div>
                            <p
                              className="addAuthor"
                              onClick={(e) => {
                                setAuthors([...authors, { author: "", website: "" }]);
                              }}
                            >
                              <img src={plus} alt="Plus" />
                              Add additional author
                            </p>
                          </div>
                        </div>

                      </div>
                    ) : (
                      ""
                    )}

                    <div className="newPageRightRow categoryFieldDrop">
                      <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown4}>
                        <span>Categories</span>
                        <div className="dropdownIcon">
                          <img src={iconDropDown} alt="dropdown" />
                        </div>
                      </p>

                      <div className={`contentDrop ${isOpen4 ? 'expanded' : 'collapse'}`}>
                        <div className="adressInputBlock urlInput">
                          <img className="adressLink" src={search} alt="Search" />
                          <input
                            type="text"
                            placeholder="Search"
                            autocomplete="off"
                            value={searchCategory}
                            onChange={(e) => setSearchCategory(e.target.value)}
                            onKeyPress={handleKeyPress}
                          />
                          <ul className="list-search categories-list">
                            {
                              filteredCategories
                                .filter((category) => category.toLowerCase().includes(searchCategory.toLowerCase()))
                                .map((category, index) => (
                                  <li key={index}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={selectedCategories.includes(category)}
                                        onChange={() => handleCheckboxChange(category)}
                                      />
                                      <p>{category}</p>
                                    </label>
                                  </li>
                                ))
                            }
                          </ul>

                          <button className="new-category" onClick={handleNewCategoryClick}>
                            <img src={plus} alt="Plus" /> New Category
                          </button>

                          {validateCategories === true ? (
                            <p className="validateError">The field is not filled</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>


                    </div>
                    <div className="newPageRightRow desktopFeatures">
                      {publicationType === "Regular" || publicationType === "File" | publicationType === "Images" ? (
                        <div className="newPageRight">
                          <p className="newPageRightTitle iconDropWrap imageUpload" onClick={toggleDropdown5}>
                            <span>Featured image</span>
                            <div className="dropdownIcon">
                              <img src={iconDropDown} alt="dropdown" />
                            </div>
                          </p>

                          <div className={`contentDrop ${isOpen5 ? 'expanded' : 'collapse'}`}>
                            <div>
                              <p className="newPageRightFullUrl">
                                <span>Display at the end of the publication...</span>
                              </p>
                              <div
                                className="newPageRightRow imageUploadBox"
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                              >
                                {saveFeatureImage ? (
                                  <div
                                    id="newpageImageUpload"
                                    className={
                                      isDragging
                                        ? "draging newpageImageUpload"
                                        : "newpageImageUpload"
                                    }
                                    onClick={handleClick}
                                  >
                                    {loadingFeatureImg === true &&
                                      featuredImageModal === false ? (
                                      <div className="featureImg-loader">
                                        <Loader />
                                      </div>
                                    ) : (
                                      <>
                                        <div onClick={handleClick}>
                                          <img
                                            className="newpage_CoverImg"
                                            src={featureImage}
                                            alt="featrureImage"
                                            onLoad={handleImageLoad}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    id="newpageImageUpload"
                                    className={
                                      isDragging
                                        ? "draging newpageImageUpload"
                                        : "newpageImageUpload"
                                    }
                                    onClick={() => {
                                      // document.getElementById("feature-img").click()
                                      setShowUploadMediaPopup(true);
                                      setUploadMediaPopupFor('feature-image');
                                      setUploadMediaPopupTab('upload');
                                    }}
                                  >
                                    {loadingFeatureImg === true ? (
                                      <div className="featureImg-loader">
                                        <Loader />
                                      </div>
                                    ) : (
                                      <div className="featureImgMiddleContent">
                                        <img
                                          className="fileUploadCover_Img"
                                          src={featured}
                                          alt="FeaturedImage"
                                        />
                                        <p className="newPageImageUploadText">
                                          <span>Upload</span> or <span onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media'); }}>Open Media Library</span>
                                          <br />
                                          JPG, PNG (max. 128MB)
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                )}
                                <input
                                  type="file"
                                  id="feature-img"
                                  ref={fileInputRef}
                                  onChange={(e) => {
                                    handleFileUpload(e);
                                    setValidateImage(false);
                                  }}
                                  accept="image/*"
                                  hidden
                                />
                              </div>
                              {validateImage === true ? (
                                <p className="validateError">The field is not filled</p>
                              ) : (
                                ""
                              )}
                              <input
                                type="text"
                                autocomplete="off"
                                className="img-captions"
                                placeholder="Image caption (optional)"
                                value={imageCaption}
                                onChange={(e) => { setimageCaption(e.target.value) }}
                              />
                            </div>
                          </div>

                        </div>
                      ) : (
                        <div className="newPageRight">
                          <p className="newPageRightTitle iconDropWrap imageUpload" onClick={toggleDropdown8}>
                            <span>Featured image</span>
                            <div className="dropdownIcon">
                              <img src={iconDropDown} alt="dropdown" />
                            </div>
                          </p>

                          <div className={`contentDrop ${isOpen8 ? 'expanded' : 'collapse'}`}>
                            <div className="list-search">
                              {
                                isHasImage &&
                                <label className="feature-img">
                                  <input type="checkbox" checked={isImageFromLink} onChange={(e) => { handleFeatureCheckbox(e) }} />
                                  <p>{publicationType == 'Link' ? 'Take image from URL' : 'Take image from video'} </p>
                                </label>
                              }

                              {
                                !isImageFromLink &&
                                <>
                                  <div
                                    className="newPageRightRow imageUploadBox"
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                  >
                                    {saveFeatureImage ? (
                                      <div
                                        id="newpageImageUpload"
                                        className={
                                          isDragging
                                            ? "draging newpageImageUpload"
                                            : "newpageImageUpload"
                                        }
                                        onClick={handleClick}
                                      >
                                        {loadingFeatureImg === true &&
                                          featuredImageModal === false ? (
                                          <div className="featureImg-loader">
                                            <Loader />
                                          </div>
                                        ) : (
                                          <>
                                            <div onClick={handleClick}>
                                              <img
                                                className="newpage_CoverImg"
                                                src={featureImage}
                                                alt="featrureImage"
                                                onLoad={handleImageLoad}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        id="newpageImageUpload"
                                        className={
                                          isDragging
                                            ? "draging newpageImageUpload"
                                            : "newpageImageUpload"
                                        }
                                        onClick={() => {
                                          // document.getElementById("feature-img").click()
                                          setShowUploadMediaPopup(true);
                                          setUploadMediaPopupFor('feature-image');
                                          setUploadMediaPopupTab('upload');
                                        }}
                                      >
                                        {loadingFeatureImg === true ? (
                                          <div className="featureImg-loader">
                                            <Loader />
                                          </div>
                                        ) : (
                                          <div className="featureImgMiddleContent">
                                            <img
                                              className="fileUploadCover_Img"
                                              src={featured}
                                              alt="FeaturedImage"
                                            />
                                            <p className="newPageImageUploadText">
                                              <span>Upload</span> or <span onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media'); }}>Media Library</span>
                                              <br />
                                              jpg, png (max. 128MB)
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    <input
                                      type="file"
                                      id="feature-img"
                                      ref={fileInputRef}
                                      onChange={(e) => {
                                        handleFileUpload(e);
                                        setValidateImage(false);
                                      }}
                                      accept="image/*"
                                      hidden
                                    />
                                  </div>
                                  {validateImage === true ? (
                                    <p className="validateError">The field is not filled</p>
                                  ) : (
                                    ""
                                  )}
                                  <input
                                    type="text"
                                    autocomplete="off"
                                    className="img-captions"
                                    placeholder="Image caption (optional)"
                                    value={imageCaption}
                                    onChange={(e) => { setimageCaption(e.target.value) }}
                                  />
                                </>
                              }
                            </div>
                          </div>

                        </div>
                      )}

                    </div>

                    <div className="newPageRightRow buttonsMobile">
                      <div className="buttonsMobileView">
                        {published === false && draft === false ? (
                          <div className="newpageRightButtons">
                            <button
                              onClick={(e) => {
                                validateFields();
                                if (pageImage === '') {
                                  setValidateImage(true);
                                } else {
                                  setValidateImage(false);
                                }
                                if (billingDetails.permission.canPublishPublication === 'no') {
                                  setIsDraftOpen(false);
                                  setIsPublishedOpen(false);
                                  setShowUpgradeModel(true);
                                  return false;
                                }
                                if (publicationType === "Regular") {
                                  if (dbPageTitle.length > 0 && dbPageSubTitle.length > 0 && updatedContent.length > 0 && pageImage !== '' && selectedCategories.length > 0 && timeErrorMessage == '') {
                                    if (!datetimevalidationCheck(null, null)) {
                                      return false;
                                    }

                                    setIsLoading(true);
                                    addData(true, "Published");
                                    setStatus(isSchedule ? 'Schedule' : 'Published');
                                    setPublished(true);
                                    billingData();
                                    setOpenPublishPopup(true);
                                  }
                                }
                                if (publicationType === "Link") {
                                  if (dbPageTitle.length > 0 && editorContent.length > 0 && shareUrl.length > 0 && selectedCategories.length > 0 && timeErrorMessage == '') {
                                    if (!datetimevalidationCheck(null, null)) {
                                      return false;
                                    }

                                    setIsLoading(true);
                                    addData(true, "Published");
                                    setStatus(isSchedule ? 'Schedule' : 'Published');
                                    setPublished(true);
                                    billingData();
                                    setOpenPublishPopup(true);
                                  }
                                }
                                if (publicationType === "File") {
                                  if (dbPageTitle.length > 0 && dbPageSubTitle.length > 0 && pageImage !== '' && selectedCategories.length > 0 && fileImage !== '' && timeErrorMessage == '') {
                                    if (!datetimevalidationCheck(null, null)) {
                                      return false;
                                    }

                                    setIsLoading(true);
                                    addData(true, "Published");
                                    setStatus(isSchedule ? 'Schedule' : 'Published');
                                    setPublished(true);
                                    billingData();
                                    setOpenPublishPopup(true);
                                  }
                                }
                              }}
                            >
                              {isLoading ?
                                <div className="publishbutton-loader">
                                  <div className="ring-loader"></div>
                                </div>
                                :
                                <>
                                  <img src={send} alt="Send" />
                                  <p>{isSchedule ? 'Schedule' : 'Publish'}</p>
                                </>}
                            </button>
                            <button onClick={(e) => {
                              draftValidateFields();
                              if (dbPageTitle.length > 0) {
                                setIsDraftLoading(true);
                                addData(true, "Draft");
                                setStatus('Draft');
                                setDraft(true);
                              }
                            }}>
                              {isDraftLoading ?
                                <div className="draftButton-loader">
                                  <div className="ring-loader cancel"></div>
                                </div>
                                :
                                <>
                                  Save as draft
                                </>}
                            </button>
                          </div>
                        ) : (
                          ""
                        )}

                        {published === true ? (
                          <div>
                            <div className="newpageRightButtons" ref={dotsRef}>

                              <button onClick={(e) => { handlePublish(e)}}>
                                {isLoading ?
                                  <div className="updatebutton-loader ">
                                    <div className="ring-loader"></div>
                                  </div>
                                  :
                                  <>
                                    <p>Update</p>
                                  </>}
                              </button>
                              <button
                                className="newpageMoreButtons"
                                onClick={(e) => setIsPublishedOpen(!isPublishedOpen)}
                              >
                                <img src={dots} alt="Dots" />
                              </button>

                              {isPublishedOpen ? (
                                <div className="publishedButtonsList">
                                  <ul>
                                    <li
                                      className="publish-li"
                                      onClick={handleChangeToDraft}
                                    >
                                      Change to draft
                                    </li>
                                    <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <p className="publishStatus">
                              {
                                status == 'Schedule' ?
                                  <>Status: <span>{status}d for {saveScheduleDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} at {saveScheduleHour}:{saveScheduleMinute}</span></>
                                  :
                                  <>Status: <span>{status}</span></>
                              }
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        {draft === true ? (
                          <div>
                            <div className="newpageRightButtons" ref={dotsRef}>
                              <button onClick={(e) => { handleDraft(e) }}>
                                {isLoading ?
                                  <div className="updatebutton-loader ">
                                    <div className="ring-loader"></div>
                                  </div>
                                  :
                                  <>
                                    <p>Update</p>
                                  </>}
                              </button>
                              <button
                                className="newpageMoreButtons"
                                onClick={(e) => setIsDraftOpen(!isDraftOpen)}
                              >
                                <img src={dots} alt="Dots" />
                              </button>

                              {isDraftOpen ? (
                                <div className="publishedButtonsList">
                                  <ul>
                                    <li
                                      className="publish-li"
                                      onClick={(e) => {
                                        validateFields();
                                        if (publicationType === "Regular") {
                                          if (dbPageTitle.length > 0 && dbPageSubTitle.length > 0 && updatedContent.length > 0 && pageImage !== '' && selectedCategories.length > 0 && timeErrorMessage == '') {
                                            handleChangeToPublish(e, () => setIsLoading(true))
                                          }
                                        }
                                        if (publicationType === "Link") {
                                          if (dbPageTitle.length > 0 && editorContent.length > 0 && shareUrl.length > 0 && selectedCategories.length > 0 && timeErrorMessage == '') {
                                            handleChangeToPublish(e, () => setIsLoading(true))
                                          }
                                        }
                                        if (publicationType === "File") {
                                          if (dbPageTitle.length > 0 && dbPageSubTitle.length > 0 && pageImage !== '' && selectedCategories.length > 0 && fileImage !== '' && timeErrorMessage == '') {
                                            handleChangeToPublish(e, () => setIsLoading(true))
                                          }
                                        }
                                      }}
                                    >
                                      {isSchedule ? "Schedule" : "Publish"}
                                    </li>
                                    <li className="publish-li" onClick={handelSingleDelete} >Delete</li>
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <p className="publishStatus">
                              Status: <span className="draftStatus">{status}</span>
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {(isPopupOpenDeleteAccount || showCategoryField) && <div className="popupModalOverlay"></div>}

            <AddCategoryPage isOpen={showCategoryField} onClose={closeCategoryField} data={categories} setCategory={setCategories} setSelectedCategory={setSelectedCategories} isCategoryPage="no" userId={id} setOpenNotify={setCategoriesNotify}/>
            <DeletePublicationModel isOpen={isPopupOpenDeleteAccount} item={data} selectedItems={data} onClose={closeAccountDeletedPopup} setOpen={setOpen} type="page" redirect={`users/${id}/publications`} fetchData={fetchData} />
            { categoriesNotify && 
                <Notification title={`Category has been successfully added!`} setOpenNotify={setCategoriesNotify} type={"success"} openNotify={categoriesNotify} />
            }
            {featuredImageModal && (
              <>
                <div className="imagCropModal">
                  <div className="featuredImageModal featurboxModal" ref={modalRef}>
                    <div className="modal-content">
                      {uploadedImage === null ? (
                        <div
                          className="newPageRightRow"
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                        >
                          {((saveFeatureImage && saveFeatureImage === oldImage) ||
                            saveFeatureImage !== featureImage) &&
                            (saveFeatureImage ? (
                              <div
                                id="newpageImageUpload"
                                className={
                                  isDragging
                                    ? "draging newpageImageUpload"
                                    : "newpageImageUpload"
                                }
                              >
                                {loadingFeatureImg === true ? (
                                  <div className="featureImg-loader">
                                    <Loader />
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <div className="crop-container">
                                        <ImageEditor
                                          type="featureImg"
                                          image={featureImage}
                                          oldImage={oldImage}
                                          setOldImage={setOldImage}
                                          setFeatureImage={setFeatureImage}
                                          setUploadedImage={setUploadedImage}
                                          replaceImage={handleFileUpload}
                                          zoom={zoom}
                                          position={position}
                                          setZoom={handleZoom}
                                          setPosition={handlePosition}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : (
                              <div
                                id="newpageImageUpload"
                                className={
                                  isDragging
                                    ? "draging newpageImageUpload"
                                    : "newpageImageUpload"
                                }
                                onClick={() =>
                                  document.getElementById("feature-img").click()
                                }
                              >
                                {loadingFeatureImg === true ? (
                                  <div className="featureImg-loader">
                                    <Loader />
                                  </div>
                                ) : (
                                  <>
                                    <div className="featureImgMiddleContent">
                                      <img
                                        className="fileUploadCover_Img"
                                        src={featured}
                                        alt="FeaturedImage"
                                      />
                                      <p className="newPageImageUploadText">
                                        <span>Click to upload</span> or drag and drop{" "}
                                        <br />
                                        JPG or PNG (max. 800x400)
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}

                          {featureImage &&
                            saveFeatureImage === featureImage &&
                            (featureImage ? (
                              <div
                                id="newpageImageUpload"
                                className={
                                  isDragging
                                    ? "draging newpageImageUpload"
                                    : "newpageImageUpload"
                                }
                              >
                                {loadingFeatureImg === true ? (
                                  <div className="featureImg-loader">
                                    <Loader />
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <div className="crop-container">
                                        <ImageEditor
                                          type="featureImg"
                                          image={featureImage}
                                          oldImage={oldImage}
                                          setOldImage={setOldImage}
                                          setFeatureImage={setFeatureImage}
                                          setUploadedImage={setUploadedImage}
                                          replaceImage={handleFileUpload}
                                          zoom={zoom}
                                          position={position}
                                          setZoom={handleZoom}
                                          setPosition={handlePosition}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : (
                              <div
                                id="newpageImageUpload"
                                className={
                                  isDragging
                                    ? "draging newpageImageUpload"
                                    : "newpageImageUpload"
                                }
                                onClick={() => {
                                  // document.getElementById("feature-img").click()
                                  setShowUploadMediaPopup(true);
                                  setUploadMediaPopupFor('feature-image');
                                  setUploadMediaPopupTab('upload')
                                }}
                              >
                                {loadingFeatureImg === true ? (
                                  <div className="featureImg-loader">
                                    <Loader />
                                  </div>
                                ) : (
                                  <>
                                    <div className="featureImgMiddleContent">
                                      <img
                                        className="fileUploadCover_Img"
                                        src={featured}
                                        alt="FeaturedImage"
                                      />
                                      <p className="newPageImageUploadText">
                                        <span>Upload</span> or <span onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media'); }}>Media Library</span>
                                        <br />
                                        jpg, png (max. 128MB)
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}

                          <input
                            type="file"
                            id="feature-img"
                            onChange={handleFileUpload}
                            accept="image/*"
                            hidden
                          />
                        </div>
                      ) : (
                        <div>
                          <div className="crop-container">
                            {featureImage && (
                              <ImageEditor
                                type="featureImg"
                                image={featureImage}
                                oldImage={oldImage}
                                setFeatureImage={setFeatureImage}
                                setUploadedImage={setUploadedImage}
                                replaceImage={handleFileUpload}
                                zoom={zoom}
                                position={position}
                                setZoom={handleZoom}
                                setPosition={handlePosition}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      <div className="replaceImageRange">
                        <div className="replaceImgBtn">
                          <button
                            className="uploadImage"
                            type="button"
                            onClick={() => handleReplaceImg()}
                          >
                            <span>Replace Image</span>
                          </button>
                        </div>
                        <div className="imgRange">
                          <img className="coverImgLeft" src={coverImg} alt="coverImage" />
                          <input
                            id="zoom-range"
                            className="zoom-range"
                            type="range"
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onTouchStart={handleTouchStart}
                            onTouchEnd={handleTouchEnd}
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aspect={384 / 200}
                            aria-labelledby="Zoom"
                            style={{
                              background: `linear-gradient(to right, #344054 ${((zoom - 1) / 2) * 100
                                }%, #B7BCC2 0%)`,
                            }}
                            onChange={(e) => {
                              handleZoom(e.target.value);
                            }}
                          />
                          <img className="coverImgRight" src={coverImg} alt="coverImage" />
                        </div>
                      </div>
                      <div className="featuredImageModalbtn">
                        <button
                          className="cancel"
                          type="button"
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </button>
                        <button
                          className="savePicture"
                          type="button"
                          onClick={() => handleSavePicture()}
                        >
                          Save Picture
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {featuredImageModal && <div className="overlayModal"> </div>}
              </>
            )}
            {showUploadMediaPopup &&
              <UploadMediaPopup showUploadMediaPopup={showUploadMediaPopup} setShowUploadMediaPopup={setShowUploadMediaPopup} setSelectedProfileImage={setSelectedFeatureImage} selectMediaLoading={selectMediaLoading} uploadMediaPopupFor={uploadMediaPopupFor} uploadMediaPopupTab={uploadMediaPopupTab} />
            }
            {showUpgradeModel &&
              <>
                <div className="popupModalOverlay"></div>
                <div className='popupModal' ref={modelRef}>
                  <h3 className='popupModalTitle'>User need to upgrade plan if need to publish more than 5 publication. </h3>
                  <div className="popupModalBtn upgradPlanPopupBtn">
                    <button className="cancel" type="button" onClick={() => { setShowUpgradeModel(false); }} >Okay</button>
                  </div>
                </div>
              </>
            }
            {showUpgradeBtn &&
              <UpgradePopUp setShowUpgradeBtn={setShowUpgradeBtn} refrence={popupUpgradeRef} />
            }
            {openNotify && 
              <NotificationMessage title={"Your publication has been successfully published!"} text={"You can view it "} link= {"here."} to={`/users/${id}/publications/publication/${PubID}`} setOpenNotify={setOpenNotify} type={"success"} openNotify={openNotify} />
            }
            { openNotifyDraft && 
              <Notification title={"Your publication has been saved as a draft!"} setOpenNotify={setOpenNotifyDraft} type={"success"} openNotify={openNotifyDraft} />
            }
            { scheduleNotify && 
              <Notification title={`Your publication is scheduled to be published at ${scheduleHour}:${scheduleMinute}.`} setOpenNotify={setScheduleNotify} type={"success"} openNotify={scheduleNotify} />
            }
            {updateDraftNotify &&
              <NotificationMessage title={"Your publication has been successfully updated!"} text={"You can view it "} link= {"here."} to={`/users/${id}/publications/publication/${PubID}`} setOpenNotify={setUpdateDraftNotify} type={"success"} openNotify={updateDraftNotify} />
            }
          </section>
          :
          <Error />
      }
    </>
  );
}
export default NewPublication;