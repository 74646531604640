import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { useState } from 'react';
import ReactPlayer from 'react-player/lazy';

// Utility function to convert Vimeo URL to embeddable format
const convertToEmbedUrl = (url) => {
  const regex = /(?:vimeo\.com\/)(\d+)/;
  const match = url.match(regex);
  return match ? `https://player.vimeo.com/video/${match[1]}` : url; // Return embeddable URL or original if not a match
};

const VimeoBlockView = (props) => {
  const { editor,getPos , node } = props;

  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleVideoReady = () => {
    setIsVideoReady(true);
  };

  const handleVideoError = (error) => {
    setIsError(true);
    setErrorMessage(error.message);
  };

  const handleCaptionChange = (e) => {
    const caption = e.target.innerText; // Get the updated caption text
    // Update the caption using updateAttributes
    editor.commands.updateAttributes(node.type.name, {
      ...node.attrs, // Keep existing attributes
      caption, // Update caption attribute
    });
  };

  const handleCaptionClick = () => {
    const nodePosition = getPos(); 
    editor.commands.setNodeSelection(nodePosition);
};
  return (
    <NodeViewWrapper data-type="vimeo">
      <div className="video-wrapper vimeo-video">
        {!isVideoReady && !isError && (
          <div contentEditable={false} className="skeleton-video-wrapper">
            <div className="skeleton-video"></div>
          </div>
        )}
        <div contentEditable={false} className={isVideoReady ? 'player-wrapper player-ready' : 'player-wrapper'}>
          <ReactPlayer
            className='react-player'
            url={convertToEmbedUrl(node.attrs.videourl)} // Ensure conversion in the editor
            width='100%'
            height='100%'
            controls
            onReady={handleVideoReady}
            onError={handleVideoError}
          />
        </div>
        {!isError && (
          <div className='video-title' contentEditable={false}>
            <p
              className='video-caption'
              contentEditable={true}
              onBlur={handleCaptionChange} // Triggered when the user finishes editing
              suppressContentEditableWarning={true} // Prevent warning about contentEditable
              onClick={handleCaptionClick}
            >
              {node.attrs.caption}
            </p>
          </div>
        )}
        {isError && (
          <p className='error-message'>{errorMessage}</p>
        )}
      </div>
    </NodeViewWrapper>
  );
};

export const VimeoBlock = Node.create({
  name: 'vimeoBlock',

  group: 'block',

  defining: true,

  isolating: true,

  addAttributes() {
    return {
      videourl: {
        default: '',
      },
      caption: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        getAttrs: (node) => {
          const videourl = node.querySelector('iframe')?.src || '';
          const caption = node.querySelector('.video-caption')?.innerText || '';
          return {
            videourl,
            caption,
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      { 'data-type': this.name, class: 'video-wrapper vimeo-video vimeo-block' }, // Added class for styling
      [
        'div',
        { class: 'player-wrapper' },
        [
          'iframe',
          {
            src: convertToEmbedUrl(HTMLAttributes.videourl), // Convert URL to embeddable format
            class: 'react-player',
            allow: 'autoplay; fullscreen',
            frameborder: '0',
            allowfullscreen: true,
          },
        ],
      ],
      HTMLAttributes.caption 
      ? [
          'div',
          { class: 'video-title' }, // Ensure the caption is part of the HTML output
          ['p', { class: 'video-caption' }, HTMLAttributes.caption], // Caption rendering
        ]
      : '',
    ];
  },

  addCommands() {
    return {
      setVimeoBlock:
        (options) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: {
                videourl: convertToEmbedUrl(options?.videourl || ''),
                caption: options?.caption || '',
              },
            });
          },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(VimeoBlockView);
  },
});
