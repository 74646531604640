import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import ReactPlayer from 'react-player';
import { useState } from 'react';

const VideoBlockView = (props) => {
    const { editor, getPos, node } = props;
    const isFullDisplay = node.attrs.layout || "default";
    const isLoopActive = node.attrs.loop || false;
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [caption, setCaption] = useState(node.attrs.caption || '');

    const handleCaptionBlur = () => {
        if (caption !== node.attrs.caption) {
            editor.commands.updateAttributes('VideoBlock', { caption:caption });
        }
    };

    const handleVideoReady = () => {
        setIsVideoReady(true);
    };

    const handleCaptionClick = () => {
        const nodePosition = getPos(); 
        editor.commands.setNodeSelection(nodePosition);
    };
    return (
        <NodeViewWrapper data-type='video'>
            <div className='video-wrapper video-preview' >
                {node.attrs.videoUrl ? (
                    <div className={`image-preview image-width-${isFullDisplay}`}
                        data-id={node.attrs.videoId}
                        data-key={node.attrs.videoKey}
                        data-caption={node.attrs.caption}
                        data-layout={node.attrs.layout}
                        >
                        { !isVideoReady &&
                            <div contentEditable={false} className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>
                        }
                        <div contentEditable={false} className={(isVideoReady) ? 'player-wrapper player-ready' : 'player-wrapper'}>
                            <ReactPlayer
                                className='react-player'
                                url={node.attrs.videoUrl}
                                width='100%'
                                height='100%'
                                light={node.attrs.thumbnailurl}
                                controls
                                onReady={handleVideoReady}
                                loop={isLoopActive}
                            />
                        </div>
                        <div className='video-title' contentEditable={false}>
                            <p 
                             className='video-caption' 
                             suppressContentEditableWarning 
                             contentEditable={true} 
                             onInput={(e) => setCaption(e.currentTarget.textContent)}
                             onBlur={handleCaptionBlur}
                             onClick={handleCaptionClick}
                             >
                                {node.attrs.caption}
                            </p>
                        </div>
                    </div>
                ) : (
                    <p>No video available</p>
                )}
            </div>
        </NodeViewWrapper>
    );
};

export const VideoBlock = Node.create({
    name: 'VideoBlock',

    group: 'block',
    defining: true,
    isolating: true,

    addAttributes() {
        return {
            videoUrl: {
                default: '',
            },
            caption: {
                default: '',
            },
            width: {
                default: 'auto', // Default width value
            },
            layout:{
                default:"default"
            },
            thumbnailurl: {
                default:''
            },
            loop:{
                default: false
            },
            videoAlt : {
                default: ""
            },
            videoId: {
                default: ""
            },
            videoKey: {
                default: ""
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
                getAttrs: (dom) => {
                    const video = dom.querySelector('video');
                    return {
                        videoUrl: video ? video.getAttribute('src') : '',
                        caption: dom.getAttribute('data-caption') || '',
                        layout: dom.getAttribute('data-layout') || 'default',
                        width: dom.style.width || 'auto',
                        videoId: dom.getAttribute('data-id') || '',
                        videoKey: dom.getAttribute('data-key') || '',
                    };
                },
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        const { videoUrl, width, caption, layout, videoId, videoKey } = HTMLAttributes;
        const style = `width: ${width}; height: auto;`;
    
        return [
            'div',
            { 
                'data-type': this.name, 
                'data-caption': caption, 
                'data-layout': layout,
                'data-id': videoId,
                'data-key': videoKey,
                class: 'video-block',
                style: `width: ${width}` 
            },
            videoUrl
                ? [
                    'div',
                    { class: `video-preview image-width-${layout}` }, // Similar to ImageBlock class
                    [
                        'video',
                        { 
                            src: videoUrl, 
                            controls: true,
                            style 
                        }
                    ],
                    caption
                        ? ['p', { class: 'video-caption' }, caption]
                        : ''
                ]
                : ['p', {}, 'No video available'],
        ];
    },

    addCommands() {
        return {
            setVideoBlock:
                (options) =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: {
                                videoUrl: options?.videoUrl || '',
                                caption: options?.caption || '',
                                width: options?.width || 'auto', 
                                layout: options?.layout || "default",
                                loop: options?.loop || false,
                                videoAlt: options?.videoAlt || '',
                                videoId: options?.videoId || '',
                                videoKey: options?.videoKey || ''
                            },
                        });
                    },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(VideoBlockView);
    },
});


