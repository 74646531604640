import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { useState } from 'react';
import ReactPlayer from 'react-player/lazy';

// Utility function to convert YouTube URL to embeddable format
const convertToEmbedUrl = (url) => {
  const regex = /(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&]+)/;
  const match = url.match(regex);
  return match ? `https://www.youtube.com/embed/${match[1]}` : url; // Return embeddable URL or original if not a match
};

const YouTubeBlockView = (props) => {
  const { editor, getPos, node } = props;

  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleVideoReady = () => {
    setIsVideoReady(true);
    setIsError(false); // Reset error state when video is ready
  };

  const handleVideoError = (error) => {
    setIsError(true);
    setErrorMessage(error.message || 'An error occurred while loading the video');
  };

  const handleCaptionChange = (e) => {
    const caption = e.target.innerText; // Get the updated caption text
    // Update the caption using updateAttributes
    editor.commands.updateAttributes(node.type.name, {
      ...node.attrs, // Keep existing attributes
      caption, // Update caption attribute
    });
  };
  const handleCaptionClick = () => {
    const nodePosition = getPos(); 
    editor.commands.setNodeSelection(nodePosition);
};

  return (
    <NodeViewWrapper data-type="youtube">
      <div className="video-wrapper youtube-video">
        {!isVideoReady && !isError && (
          <div contentEditable={false} className="skeleton-video-wrapper">
            <div className="skeleton-video"></div>
          </div>
        )}
        <div contentEditable={false} className={isVideoReady ? 'player-wrapper player-ready' : 'player-wrapper'}>
          <ReactPlayer
            className="react-player"
            url={convertToEmbedUrl(node.attrs.videourl)}
            width="100%"
            height="100%"
            controls
            onReady={handleVideoReady}
            onError={handleVideoError}
          />
        </div>

        {!isError && (
          <div className="video-title" contentEditable={false}>
            <p
              className="video-caption"
              contentEditable={true}
              onBlur={handleCaptionChange} // Triggered when the user finishes editing
              suppressContentEditableWarning={true} // Prevent warning about contentEditable
              onClick={handleCaptionClick}
            >
              {node.attrs.caption}
            </p>
          </div>
        )}

        {isError && (
          <p className='error-message'>{errorMessage}</p>
        )}
      </div>
    </NodeViewWrapper>
  );
};

export const YouTubeBlock = Node.create({
  name: 'youtubeBlock',

  group: 'block',

  defining: true,

  isolating: true,

  addAttributes() {
    return {
      videourl: {
        default: '',
      },
      caption: {
        default: '',  // Ensure caption is part of attributes
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        getAttrs: (node) => {
          const element = node;
          const videourl = element.querySelector('iframe')?.getAttribute('src') || '';
          const caption = element.querySelector('.video-caption')?.innerText || '';

          return {
            videourl,
            caption,
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      { 'data-type': this.name, class: 'video-wrapper youtube-video youtube-block' },
      [
        'div',
        { class: 'player-wrapper' },
        HTMLAttributes.videourl
          ? ['iframe', {
              src: convertToEmbedUrl(HTMLAttributes.videourl), // Convert URL to embeddable format
              class: 'react-player',
              width: '100%',
              height: '100%',
              frameborder: '0',
              allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
              allowfullscreen: true,
            }]
          : '',
      ],
      HTMLAttributes.caption
      ? [
        'div',
        { class: 'video-title' },  // Ensure the caption is part of the HTML output
        ['p', { class: 'video-caption' }, HTMLAttributes.caption],  // Render empty string if no caption
        ] 
      : '',
    ];
  },

  addCommands() {
    return {
      setYouTubeBlock:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              videourl: convertToEmbedUrl(options?.videourl || ''),
              caption: options?.caption || '',  // Set caption during command execution
            },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(YouTubeBlockView);
  },
});
