import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { useState } from 'react';

const ImageBlockView = (props) => {
    const { editor, getPos, node } = props;
    const isFullDisplay = node?.attrs?.layout || "default";
    const target = node?.attrs?.target || false;
    const [caption, setCaption] = useState(node?.attrs?.caption || '');

    const handleCaptionBlur = () => {
        if (caption !== node.attrs.caption) {
            editor.commands.updateAttributes('imageBlock', { caption });
        }
    };
    const handleCaptionClick = () => {
        const nodePosition = getPos(); 
        editor.commands.setNodeSelection(nodePosition);
    };

    return (
        <NodeViewWrapper data-type='imageBlock' >
            <div className='image-block'>
                {node.attrs.imageUrl ? (
                    <div
                        className={`image-preview image-width-${isFullDisplay}`}
                        data-caption={node.attrs.caption}
                        data-layout={node.attrs.layout}
                        data-imagelink={node.attrs.imagelink}
                        data-target={node.attrs.target}
                        data-alt={node.attrs.imageAlt}
                        data-id={node.attrs.imageId}
                        data-key={node.attrs.imageKey}
                    >
                        {node.attrs.imagelink ? (
                            <a
                                href={node.attrs.imagelink}
                                target={target ? '_blank' : "_self"}
                                rel={target ? 'noopener noreferrer' : undefined}
                            >
                                <img src={node.attrs.imageUrl} alt={node.attrs.imageAlt || "Uploaded Image"} />
                            </a>
                        ) : (
                            <img src={node.attrs.imageUrl} alt={node.attrs.imageAlt || "Uploaded Image"} />
                        )}
                        <div className='video-title image-title' contentEditable={false}>
                            <p
                                className='video-caption'
                                contentEditable
                                suppressContentEditableWarning
                                onInput={(e) => setCaption(e.currentTarget.textContent)}
                                onBlur={handleCaptionBlur}
                                onClick={handleCaptionClick}
                            >
                                {node.attrs.caption}
                            </p>
                        </div>
                    </div>
                ) : (
                    <p>No image available</p>
                )}
            </div>
        </NodeViewWrapper>
    );
};

export const ImageBlock = Node.create({
    name: 'imageBlock',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
            imageUrl: {
                default: '',
            },
            caption: {
                default: '',
            },
            width: {
                default: 'auto',
            },
            layout: {
                default: "default"
            },
            imagelink: {
                default: ""
            },
            target: {
                default: false
            },
            imageAlt : {
                default: ""
            },
            imageId: {
                default: ""
            },
            imageKey: {
                default: ""
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
                getAttrs: (dom) => {
                    const img = dom.querySelector('img');
                    return {
                        imageUrl: img ? img.getAttribute('src') : '',
                        caption: dom.getAttribute('data-caption') || '',
                        imagelink: dom.getAttribute('data-imagelink') || '',
                        layout: dom.getAttribute('data-layout') || 'default',
                        target: dom.getAttribute('data-target') === 'true',
                        width: dom.style.width || 'auto',
                        imageAlt: dom.getAttribute('data-alt') || '',
                        imageId: dom.getAttribute('data-id') || '',
                        imageKey: dom.getAttribute('data-key') || '',
                    };
                },
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        const { imageUrl, width, caption, layout, imagelink, target, imageAlt, imageId, imageKey } = HTMLAttributes;
        const style = `width: ${width}; height: auto;`;

        return [
            'div',
            { 
                'data-type': this.name, 
                'data-caption': caption, 
                'data-layout': layout,
                'data-imagelink': imagelink,
                'data-target': target,
                'data-alt': imageAlt,
                'data-id': imageId,
                'data-key': imageKey,
                class: 'image-block',
                style: `width: ${width}` 
            },
            imageUrl
                ? [
                    'div',
                    { class: `image-preview image-width-${layout}` }, // Updated to match React component
                    imagelink
                        ? [
                            'a',
                            { href: imagelink, target: target ? '_blank' : '_self', rel: target ? 'noopener noreferrer' : undefined },
                            ['img', { src: imageUrl, alt: imageAlt || 'Uploaded Image', style }]
                        ]
                        : ['img', { src: imageUrl, alt: imageAlt || 'Uploaded Image', style }],
                    caption
                        ? ['p', { class: 'image-caption' }, caption]
                        : '' 
                ]
                : ['p', {}, 'No image available'],
        ];
    },

    addCommands() {
        return {
            setImageBlock:
                (options) =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: {
                                imageUrl: options?.imageUrl || '',
                                caption: options?.caption || '',
                                width: options?.width || 'auto',
                                layout: options?.layout || "default",
                                imagelink: options?.imagelink || '',
                                target: options?.target || false,
                                imageAlt: options?.imageAlt || '',
                                imageId: options?.imageId || '',
                                imageKey: options?.imageKey || ''
                            },
                        });
                    },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(ImageBlockView);
    },
});


