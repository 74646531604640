

import { useCallback, useState } from 'react';
import LinkPreviewPanel from '../../SlashCommand/LinkPreviewPanel';
import ImageLinkEditorPreview from './ImageLinkEditorPreview';


export const ImageLinkPopover = ({ onSetLink, editLinkPopoverOpen, onUnsetLink, showEdit, setShowEdit, link, target, setImageUrl, setImageUrlTarget, setButtonAttribute }) => {
    const handleEdit = () => {
        setShowEdit(false)
    }
    return (
        <div style={{ position: 'relative' }}>
            {editLinkPopoverOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: '-200px',
                    }}
                >
                    {showEdit ? (
                        <LinkPreviewPanel url={link} onClear={onUnsetLink} onEdit={handleEdit} />
                    )
                        :
                        (
                            <ImageLinkEditorPreview initialUrl={link} initialOpenInNewTab={target === '_blank'} onSetLink={onSetLink} setShowEdit={setShowEdit} setImageUrl={setImageUrl} setImageUrlTarget={setImageUrlTarget} setButtonAttribute={setButtonAttribute}/>
                        )}
                </div>
            )}
        </div>
    );
};
