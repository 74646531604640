import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import UploadMediaPopup from '../../../../../Pages/Users/UploadMediaPopup';

const FileNodeView = ({ node, editor, getPos }) => {
    const [isFileDragging, setIsFileDragging] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [showUploadMediaPopup, setShowUploadMediaPopup] = useState(false);
    const [uploadMediaPopupFor, setUploadMediaPopupFor] = useState('');
    const [uploadMediaPopupTab, setUploadMediaPopupTab] = useState('upload');
    const [selectedMeidaType, setSelectedMeidaType] = useState([]);
    const [selectMediaLoading, setSelectMediaLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if (selectedMeidaType !== '') {
            if (uploadMediaPopupFor === 'docfiles-selection') {
                setSelectMediaLoading(false);
                setShowUploadMediaPopup(false);
                setUploadMediaPopupFor('');

                editor.chain().focus().setFileBlock({ fileUrl: selectedMeidaType?.url, fileName: selectedMeidaType?.name, fileSize: selectedMeidaType?.size, caption: selectedMeidaType?.description, fileId:selectedMeidaType?._id, fileKey:selectedMeidaType?.key, fileObjectId:selectedMeidaType?.object_id }).run();
            }
        }
    }, [selectedMeidaType]);

    const handleFileDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsFileDragging(true);
        }
    }

    const handleFileDragLeave = (e) => {
        e.preventDefault();
        setIsFileDragging(false);
    };

    const handleFileDrop = async (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        const maxSizeInBytes = 128 * 1024 * 1024; // 128MB
        const allowedFileTypes = [ 'pdf', 'docx', 'doc', 'pptx', 'ppt', 'pptm', 'xls', 'xlsx', 'xlsm'];
        
        if (files.length > 1) {
            setErrorMessages(['You can only upload one file at a time.']);
            setIsFileDragging(false);
            return;
        }
        for (const file of files) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
    
            if (!allowedFileTypes.includes(fileExtension)) {
                setErrorMessages([`File ${file.name} is not an allowed type. Only PDF, DOCX, DOC, PPTX, PPT, PPTM, XLS, XLSX, and XLSM are allowed.`]);
                setIsFileDragging(false);
                continue; // Skip this file
            }
    
            if (file.size > maxSizeInBytes) {
                setErrorMessages([`File ${file.name} exceeds the maximum size of 128MB.`]);
                setIsFileDragging(false);
                continue; // Skip this file
            }
    
            const formData = new FormData();
            formData.append('files', file); // Append each file individually
    
            try {
                setLoadingFiles(true);
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
                setErrorMessages([])
                editor.chain().focus().setFileBlock({
                    fileUrl: res?.data?.result[0]?.url,
                    fileName: res?.data?.result[0]?.name,
                    fileSize: res?.data?.result[0]?.size,
                    caption: res?.data?.result[0]?.description,
                    fileId: res?.data?.result[0]?._id,
                    fileKey: res?.data?.result[0]?.key,
                    fileObjectId: res?.data?.result[0]?.object_id
                }).run();
    
            } catch (error) {
                console.log(error);
            }finally {
                setLoadingFiles(false);
            }
            setIsFileDragging(false);
        }
    };
    

    return (
        <>
        <NodeViewWrapper>
            <div data-type="file" className={`image-node-wrapper`} data-drag-handle>
                <div>
                    <div className={`newpageLeftRow textBlock uploadImageBlock ${loadingFiles ? 'uploadRunning' : ''}`}>
                        <div
                            className={isFileDragging ? 'dragging fileUploadBox' : 'fileUploadBox'}
                            onClick={() => {
                                setShowUploadMediaPopup(true);
                                setUploadMediaPopupFor('docfiles-selection');
                                setUploadMediaPopupTab('upload');
                              }}
                            onDragOver={handleFileDragOver}
                            onDragLeave={handleFileDragLeave}
                            onDrop={handleFileDrop}
                        >
                            {loadingFiles ? (
                                <div className="progress-wrap">
                                    <div className="progress">
                                        <div className="color"></div>
                                    </div>
                                </div>
                            ) : (

                                <div className="uploadFileBlock" >
                                    <div className="featureImgMiddleContent">
                                        <svg width="21" height="20" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='fileUploadCover_Img'>
                                            <path d="M11 1.70215V4.80005C11 5.22009 11 5.43011 11.0817 5.59055C11.1537 5.73167 11.2684 5.8464 11.4095 5.91831C11.5699 6.00005 11.78 6.00005 12.2 6.00005H15.2979M12.5 9.75H6.5M12.5 12.75H6.5M8 6.75H6.5M11 1.5H7.1C5.83988 1.5 5.20982 1.5 4.72852 1.74524C4.30516 1.96095 3.96095 2.30516 3.74524 2.72852C3.5 3.20982 3.5 3.83988 3.5 5.1V12.9C3.5 14.1601 3.5 14.7902 3.74524 15.2715C3.96095 15.6948 4.30516 16.039 4.72852 16.2548C5.20982 16.5 5.83988 16.5 7.1 16.5H11.9C13.1601 16.5 13.7902 16.5 14.2715 16.2548C14.6948 16.039 15.039 15.6948 15.2548 15.2715C15.5 14.7902 15.5 14.1601 15.5 12.9V6L11 1.5Z" stroke="#475467" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <p className="newPageImageUploadText fileupload">
                                      <span>Upload</span> or <span onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('docfiles-selection'); setUploadMediaPopupTab('media'); }}>Open File Library</span>
                                      <br />
                                      PDF, DOCX, DOC, PPTX, PPT, PPTM, XLS, XLSX, XLSM (max. 128MB)
                                    </p>
                                        {errorMessages && errorMessages.length > 0 && (
                                            <div className="errorMessages">
                                                {errorMessages.map((errorMessage, index) => (
                                                    <p key={index} className="error-message">
                                                        {errorMessage}
                                                    </p>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </NodeViewWrapper>
        {showUploadMediaPopup &&
            <UploadMediaPopup showUploadMediaPopup={showUploadMediaPopup} setShowUploadMediaPopup={setShowUploadMediaPopup} setSelectedProfileImage={setSelectedMeidaType} selectMediaLoading={selectMediaLoading} uploadMediaPopupFor={uploadMediaPopupFor} uploadMediaPopupTab={uploadMediaPopupTab} />
        }
        </>
    );
};


export const File = Node.create({
    name: 'file',

    isolating: true,
    defining: true,
    group: 'block',
    draggable: true,
    selectable: true,
    inline: false,

    addAttributes() {
        return {
            fileUrl: {
                default: '',
            },
            fullDisplay: {
                default: false,
            },
            fileName: {
                default: ""
            },
            fileSize: {
                default: ""
            }

        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', { 'data-type': this.name, ...HTMLAttributes, class: 'file-block' }];
    },

    addCommands() {
        return {
            setFileUpload: (fileUrl) => ({ commands }) => {
                return commands.insertContent(`<div data-type="${this.name}" data-image-url="${fileUrl}"></div>`);
            },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(FileNodeView);
    },
});

